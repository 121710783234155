/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ContextService } from '../context.service';
import { HausratDataService } from '../tarif-data/hausrat-data.service';

@Injectable({
  providedIn: 'root'
})
export class HausratService {

  constructor(public cs: ContextService, public hausratDataService: HausratDataService) {

  }

  getCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const result = Object();
    const tarifZone = this.cs.process.risk_address.zones.ZoneTarif;
    const qm = this.cs.process.products.hausrat.input.wohnflaeche;
    const jnp_qm = this.hausratDataService.hausratData.tarife[tarifKey].tarifzonen[tarifZone];
    // let errors = null;
    let zuschlaege = 0;
    let anzZahlungen_lbl = 'jährlich';
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        anzZahlungen_lbl = 'jährlich';
        break;
      case 2:
        zuschlaege += 3;
        anzZahlungen_lbl = '1/2 jährlich';
        break;
      case 4:
        zuschlaege += 5;
        anzZahlungen_lbl = '1/4 jährlich';
        break;
      case 12:
        zuschlaege += 5;
        anzZahlungen_lbl = 'monatlich';
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.steuersatz = this.hausratDataService.hausratData.taxRate;
    result.steuersatzElementar = this.hausratDataService.hausratData.taxRateElementar;

    result.hausratNetto = Math.round(qm * jnp_qm) * (1 + zuschlaege / 100);
    result.hausratNetto = Math.round(result.hausratNetto / zahlweise) * zahlweise;
    result.hausratVersicherungssteuer = Math.round(result.hausratNetto * result.steuersatz);
    result.hausratVersicherungssteuer = Math.round(result.hausratVersicherungssteuer / zahlweise) * zahlweise;
    result.hausratZahl = result.hausratNetto + result.hausratVersicherungssteuer;
    result.hausratZahlGemZW = result.hausratZahl / zahlweise;

    const benefitCalcResult = this.getBenefitCalculation();
    result.benefitNetto = benefitCalcResult.beitragGesamtNetto;
    result.benefitVersicherungssteuer = benefitCalcResult.beitragGesamtVersicherungssteuer;
    result.benefitZahl = benefitCalcResult.beitragGesamtZahl;
    result.benefitZahlGemZW = benefitCalcResult.beitragGesamtZahlGemZW;

    if (this.cs.process.products.hausrat.input.modules.include_glas) {
      const glasCalcResult = this.getGlasCalculation();
      result.glasNetto = glasCalcResult.beitragGesamtNetto;
      result.glasVersicherungssteuer = glasCalcResult.beitragGesamtVersicherungssteuer;
      result.glasZahl = glasCalcResult.beitragGesamtZahl;
      result.glasZahlGemZW = glasCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.glasNetto = 0;
      result.glasVersicherungssteuer = 0;
      result.glasZahl = 0;
      result.glasZahlGemZW = 0;
    }

    if (this.cs.process.products.hausrat.input.modules.include_smarthome) {
      const smartHomeCalcResult = this.getSmartHomeCalculation();
      result.smartHomeNetto = smartHomeCalcResult.beitragGesamtNetto;
      result.smartHomeVersicherungssteuer = smartHomeCalcResult.beitragGesamtVersicherungssteuer;
      result.smartHomeZahl = smartHomeCalcResult.beitragGesamtZahl;
      result.smartHomeZahlGemZW = smartHomeCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.smartHomeNetto = 0;
      result.smartHomeVersicherungssteuer = 0;
      result.smartHomeZahl = 0;
      result.smartHomeZahlGemZW = 0;
    }

    let calFahrraddiebstahl = false;
    if (this.cs.process.products.hausrat.input.modules.sum_fahrraddiebstahl > 500 && tarifKey === 'premium') {
      calFahrraddiebstahl = true;
    }
    if (this.cs.process.products.hausrat.input.modules.sum_fahrraddiebstahl > 1000 && tarifKey === 'premium_plus') {
      calFahrraddiebstahl = true;
    }

    if (calFahrraddiebstahl) {
      const fahrraddiebstahlCalcResult = this.getFahrraddiebstahlCalculation();
      result.fahrraddiebstahlNetto = fahrraddiebstahlCalcResult.beitragGesamtNetto;
      result.fahrraddiebstahlVersicherungssteuer = fahrraddiebstahlCalcResult.beitragGesamtVersicherungssteuer;
      result.fahrraddiebstahlZahl = fahrraddiebstahlCalcResult.beitragGesamtZahl;
      result.fahrraddiebstahlZahlGemZW = fahrraddiebstahlCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.fahrraddiebstahlNetto = 0;
      result.fahrraddiebstahlVersicherungssteuer = 0;
      result.fahrraddiebstahlZahl = 0;
      result.fahrraddiebstahlZahlGemZW = 0;
    }

    if (this.cs.process.products.hausrat.input.modules.include_elementar) {
      const elementarCalcResult = this.getElementarCalculation();
      result.elementarNetto = elementarCalcResult.beitragGesamtNetto;
      result.elementarVersicherungssteuer = elementarCalcResult.beitragGesamtVersicherungssteuer;
      result.elementarZahl = elementarCalcResult.beitragGesamtZahl;
      result.elementarZahlGemZW = elementarCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.elementarNetto = 0;
      result.elementarVersicherungssteuer = 0;
      result.elementarZahl = 0;
      result.elementarZahlGemZW = 0;
    }

    result.gesamtNetto = result.hausratNetto + result.benefitNetto + result.elementarNetto + result.glasNetto + result.smartHomeNetto +result.fahrraddiebstahlNetto;
    result.gesamtVersicherungssteuer = result.hausratVersicherungssteuer + result.benefitVersicherungssteuer + result.elementarVersicherungssteuer + result.glasVersicherungssteuer + result.smartHomeVersicherungssteuer +result.fahrraddiebstahlVersicherungssteuer;
    result.gesamtZahl = result.hausratZahl + result.benefitZahl + result.elementarZahl + result.glasZahl + result.smartHomeZahl+ result.fahrraddiebstahlZahl;
    result.gesamtZahlGemZW = result.hausratZahlGemZW + result.benefitZahlGemZW + result.elementarZahlGemZW + result.glasZahlGemZW + result.smartHomeZahlGemZW+ result.fahrraddiebstahlZahlGemZW;
    result.glasSteuerProz = this.hausratDataService.glasData.taxRate;
    result.hausratSteuerProz = this.hausratDataService.hausratData.taxRate;
    result.hausratElementarSteuerProz = this.hausratDataService.hausratData.taxRateElementar;

    result.hauptNetto = result.hausratNetto + result.benefitNetto + result.elementarNetto + result.smartHomeNetto +result.fahrraddiebstahlNetto;
    result.hauptVersicherungssteuer = result.hausratVersicherungssteuer + result.benefitVersicherungssteuer + result.elementarVersicherungssteuer  + result.smartHomeVersicherungssteuer +result.fahrraddiebstahlVersicherungssteuer;
    result.hauptZahl = result.hausratZahl + result.benefitZahl + result.elementarZahl +  result.smartHomeZahl+ result.fahrraddiebstahlZahl;
    result.hauptZahlGemZW = result.hausratZahlGemZW + result.benefitZahlGemZW + result.elementarZahlGemZW +  result.smartHomeZahlGemZW+ result.fahrraddiebstahlZahlGemZW;
    result.hauptSteuerProz = this.hausratDataService.hausratData.taxRate;

    this.cs.process.products.hausrat.calculation = { ...result };
  }

  getGlasCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const tarifData = this.hausratDataService.glasData;
    const perqm = tarifData.tarife[tarifKey].perqm;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    const qm = this.cs.process.products.hausrat.input.wohnflaeche;
    result.beitragGesamtNetto = Math.round(qm * perqm);
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getBenefitCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const tarifData = this.hausratDataService.hausratData;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = tarifData.tarife[tarifKey].benefit;
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getSmartHomeCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const tarifData = this.hausratDataService.hausratData;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = tarifData.tarife[tarifKey].smart_home;
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getFahrraddiebstahlCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const tarifData = this.hausratDataService.hausratData;
    const result = Object();
    const risikoanschrift = this.cs.process.risk_address;
    const tarifZone = this.cs.process.risk_address.zones.ZoneTarif; //this.hausratDataService.hausratData.getTarifzone(risikoanschrift.zip);
    const zoneFahrraddiebstahl = this.hausratDataService.hausratData.tarife[tarifKey].tarifzonenFahrraddiebstahl[tarifZone];
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = zoneFahrraddiebstahl[this.cs.process.products.hausrat.input.modules.sum_fahrraddiebstahl];
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getElementarCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.hausrat.input.variant;
    const tarifData = this.hausratDataService.hausratData;
    const result = Object();
    const zuersZonen = this.hausratDataService.hausratData.tarife[tarifKey].zuersZonen;
    const qm = this.cs.process.products.hausrat.input.wohnflaeche;
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = Math.round(qm * zuersZonen[parseInt(this.cs.process.risk_address.zones.Zone, 10)]);
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }
}
