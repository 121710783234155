/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { UnfallDataService } from './tarif-data/unfall-data.service';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  activePathIndex = 0;
  activePersonIndex = 0;
  navigationPaths = [];
  unfallrentenkapitalArr = [];
  unfallRenteEuroArr = [];
  todesfallArr = [];
  krankenHausEuroArr = [];
  tagegeldEuroArr = [];
  leistungArr = [];
  fahrraddiebstahlArr = [];
  maxGipsgeld = undefined;
  displayedMaxGipsgeld = undefined;
  maxTagegeld = undefined;
  bezeichnungBeruf = undefined;
  allSigned = false;
  antragsnummer = undefined;
  signatures = [];

  tarifierungItems = [
    { name: 'Sach-/Haftpflichtversicherung', isSelected: false, isDivider: true },
    { name: 'Privathaftpflicht', isSelected: false, url: '/haftpflicht' },
    { name: 'Hausrat', isSelected: false, url: '/hausrat' },
    { name: 'Wohngebäude', isSelected: false, url: '/wohngebaude' },
    { name: 'Personenversicherung', isSelected: false, isDivider: true },
    { name: 'Unfall', isSelected: false, url: '/unfall' },
  ];


  user = {
    email: undefined,
    disabled: undefined,
    password: undefined,
    passwordConfirm: undefined,
    resetToken: undefined
  };

  session = {
    bearer: undefined,
    tarif_description: [],
    cnumberlist: []
  };


  process = {
    antrag: {
      sepa: {
        is_abweichender_kontoinhaber: false,
        abweichender_kontoinhaber: {
          salutation: undefined,
          firstname: undefined,
          lastname: undefined,
          street: undefined,
          housenumber: undefined,
          zip: undefined,
          city: undefined
        },
        iban: undefined,
        bic: undefined,
        bezeichnung: undefined,
        kto: undefined,
        blz: undefined
      },
      customer: {
        birthdate: undefined,
        place_of_birth: undefined,
        profession: undefined,
        salutation: undefined,
        firstname: undefined,
        lastname: undefined,
        street: undefined,
        housenumber: undefined,
        zip: undefined,
        city: undefined,
        phone: undefined,
        email: undefined
      },
      vorversicherungen: {
        haftpflicht: {
          is_vorhanden: false,
          versicherer: undefined,
          versicherungsnummer: undefined,
          kuendigung_durch: undefined,
          kuendigung_zum: undefined,
          has_vorschaeden: undefined,
          anzahl_vorschaeden: undefined,
          beschreibung_vorschaeden: undefined
        },
        hausrat: {
          is_vorhanden: false,
          versicherer: undefined,
          versicherungsnummer: undefined,
          kuendigung_durch: undefined,
          kuendigung_zum: undefined,
          has_vorschaeden: undefined,
          anzahl_vorschaeden: undefined,
          beschreibung_vorschaeden: undefined
        },
        wohngebaeude: {
          is_vorhanden: false,
          versicherer: undefined,
          versicherungsnummer: undefined,
          kuendigung_durch: undefined,
          kuendigung_zum: undefined,
          has_vorschaeden: undefined,
          anzahl_vorschaeden: undefined,
          beschreibung_vorschaeden: undefined
        },
        unfall: {
          is_vorhanden: false,
          versicherer: undefined,
          versicherungsnummer: undefined,
          kuendigung_durch: undefined,
          kuendigung_zum: undefined,
          has_vorschaeden: undefined,
          anzahl_vorschaeden: undefined,
          beschreibung_vorschaeden: undefined
        }
      },
      nachweisgeber: undefined
    },
    risk_address: {
      street: undefined,
      housenumber: undefined,
      zip: undefined,
      city: undefined,
      district: undefined,
      zones: undefined
    },
    advisory_protocol: {
      gespraechsdaten: {
        beratung: undefined,
        beratung_detail: undefined,
        gespraechsort: undefined,
        gespraechsort_detail: undefined,
        teilnehmer: undefined,
        teilnehmer_detail: undefined
      },
      empfehlungsgrundlage: {
        is_analysebogen: undefined,
        detail_analysebogen: undefined,
        is_verzicht_analyse: false,
        is_wunsch_kostengunstig: false,
        is_wunsch_ausgewogen: false,
        is_wunsch_umfassend: false
      },
      wuensche_und_beduerfnisse: {
        unfallversicherung: {
          is_kundenwunsch: false,
          empfehlung: undefined,
          begruendung_entscheidung: undefined,
          is_gemaess_empfehlung: false,
          begruendung_abweichende_entscheidung: undefined
        },
        wohngebeaudeversicherung: {
          is_kundenwunsch: false,
          empfehlung: undefined,
          begruendung_entscheidung: undefined,
          is_gemaess_empfehlung: false,
          begruendung_abweichende_entscheidung: undefined
        },
        hausratversicherung: {
          is_kundenwunsch: false,
          empfehlung: undefined,
          begruendung_entscheidung: undefined,
          is_gemaess_empfehlung: false,
          begruendung_abweichende_entscheidung: undefined
        },
        privathaftpflichtversicherung: {
          is_kundenwunsch: false,
          empfehlung: undefined,
          begruendung_entscheidung: undefined,
          is_gemaess_empfehlung: false,
          begruendung_abweichende_entscheidung: undefined
        },
        sonstiges: undefined,
        ausschluss_risiken: undefined,
        is_hinweis_luecken: undefined,
        hinweis_luecken_keine_versicherung: false,
        hinweis_luecken_nur_genannte_versicherungen: false
      },
      beratungsumfang: {
        offene_wuensche: undefined,
        folgetermine_zu: undefined
      },
      bestaetigung: {
        is_korrekt_analysebogen: false,
        is_korrekt_beratungsprotokoll: false,
        is_hinweis_agb: undefined,
        is_kontaktaufnahme: undefined,
        is_kundeninformation_erhalten: undefined,
        is_sonstige_erhalten: undefined,
        sonstige_detail: undefined,
      }
    },
    products: {
      payment_frequency: 1,
      haftpflicht: {
        is_selected: false,
        input: {
          variant: 'premium_plus',
          begin: undefined,
          end: undefined,
          birthdate: undefined,
          tarif: undefined
        },
        calculation: Object(),
        calculationBE: Object(),
      },
      hausrat: {
        is_selected: false,
        input: {
          variant: 'premium_plus',
          begin: undefined,
          end: undefined,
          wohnflaeche: undefined,
          modules: {
            include_benefit: true,
            include_smarthome: false,
            include_elementar: false,
            include_marktgarantie: true,
            include_glas: false,
            sum_fahrraddiebstahl: undefined
          },
          risk_address: {},
          questions: {
            is_staendig_bewohnt: false,
            is_buendiges_zylinderschloss: false,
            is_anzeigepflichtige_betriebe: false
          }
        },
        calculation: Object(),
        calculationBE: Object(),
      },
      wohngebaeude: {
        is_selected: false,
        input: {
          variant: 'premium_plus',
          begin: undefined,
          end: undefined,
          wohnflaeche: undefined,
          ursprungsbaujahr: undefined,
          modules: {
            include_benefit: true,
            include_marktgarantie: false,
            include_smarthome: false,
            include_elementar: false,
            include_glas: false,
            include_feuerohbau: false
          },
          risk_address: {},
          questions: {
            is_ein_zweifamilienhaus: false,
            is_staendig_bewohnt: false,
            is_denkmalgeschuetzt: false,
            is_weiche_dachung: false,
            is_wochenendhaus: false,
            is_anzeigepflichtige_betriebe: false,
            is_gewerbetriebe: false
          }
        },
        calculation: Object(),
        calculationBE: Object(),
      },
      unfall: {
        is_selected: false,
        input: {
          variant: 'premium_plus',
          type: 'exklusiv',
          begin: undefined,
          end: undefined,
          insured_persons: []
        },
        calculation: Object(),
        calculationBE: Object(),
      }
    },
    calculation: Object(),
    calculationBE: Object(),
  };

  constructor(private unfallDataService: UnfallDataService) {
    this.initArrays();
  }

  cleanContext() {
    const emptyProcess = {
      antrag: {
        sepa: {
          is_abweichender_kontoinhaber: false,
          abweichender_kontoinhaber: {
            salutation: undefined,
            firstname: undefined,
            lastname: undefined,
            street: undefined,
            housenumber: undefined,
            zip: undefined,
            city: undefined
          },
          iban: undefined,
          bic: undefined,
          bezeichnung: undefined,
          kto: undefined,
          blz: undefined
        },
        customer: {
          birthdate: undefined,
          place_of_birth: undefined,
          profession: undefined,
          salutation: undefined,
          firstname: undefined,
          lastname: undefined,
          street: undefined,
          housenumber: undefined,
          zip: undefined,
          city: undefined,
          phone: undefined,
          email: undefined
        },
        vorversicherungen: {
          haftpflicht: {
            is_vorhanden: false,
            versicherer: undefined,
            versicherungsnummer: undefined,
            kuendigung_durch: undefined,
            kuendigung_zum: undefined,
            has_vorschaeden: undefined,
            anzahl_vorschaeden: undefined,
            beschreibung_vorschaeden: undefined
          },
          hausrat: {
            is_vorhanden: false,
            versicherer: undefined,
            versicherungsnummer: undefined,
            kuendigung_durch: undefined,
            kuendigung_zum: undefined,
            has_vorschaeden: undefined,
            anzahl_vorschaeden: undefined,
            beschreibung_vorschaeden: undefined
          },
          wohngebaeude: {
            is_vorhanden: false,
            versicherer: undefined,
            versicherungsnummer: undefined,
            kuendigung_durch: undefined,
            kuendigung_zum: undefined,
            has_vorschaeden: undefined,
            anzahl_vorschaeden: undefined,
            beschreibung_vorschaeden: undefined
          },
          unfall: {
            is_vorhanden: false,
            versicherer: undefined,
            versicherungsnummer: undefined,
            kuendigung_durch: undefined,
            kuendigung_zum: undefined,
            has_vorschaeden: undefined,
            anzahl_vorschaeden: undefined,
            beschreibung_vorschaeden: undefined
          }
        },
        nachweisgeber: undefined
      },
      risk_address: {
        street: undefined,
        housenumber: undefined,
        zip: undefined,
        city: undefined,
        district: undefined,
        zones: undefined
      },
      advisory_protocol: {
        gespraechsdaten: {
          beratung: undefined,
          beratung_detail: undefined,
          gespraechsort: undefined,
          gespraechsort_detail: undefined,
          teilnehmer: undefined,
          teilnehmer_detail: undefined
        },
        empfehlungsgrundlage: {
          is_analysebogen: undefined,
          detail_analysebogen: undefined,
          is_verzicht_analyse: false,
          is_wunsch_kostengunstig: false,
          is_wunsch_ausgewogen: false,
          is_wunsch_umfassend: false
        },
        wuensche_und_beduerfnisse: {
          unfallversicherung: {
            is_kundenwunsch: false,
            empfehlung: undefined,
            begruendung_entscheidung: undefined,
            is_gemaess_empfehlung: false,
            begruendung_abweichende_entscheidung: undefined
          },
          wohngebeaudeversicherung: {
            is_kundenwunsch: false,
            empfehlung: undefined,
            begruendung_entscheidung: undefined,
            is_gemaess_empfehlung: false,
            begruendung_abweichende_entscheidung: undefined
          },
          hausratversicherung: {
            is_kundenwunsch: false,
            empfehlung: undefined,
            begruendung_entscheidung: undefined,
            is_gemaess_empfehlung: false,
            begruendung_abweichende_entscheidung: undefined
          },
          privathaftpflichtversicherung: {
            is_kundenwunsch: false,
            empfehlung: undefined,
            begruendung_entscheidung: undefined,
            is_gemaess_empfehlung: false,
            begruendung_abweichende_entscheidung: undefined
          },
          sonstiges: undefined,
          ausschluss_risiken: undefined,
          is_hinweis_luecken: undefined,
          hinweis_luecken_keine_versicherung: false,
          hinweis_luecken_nur_genannte_versicherungen: false
        },
        beratungsumfang: {
          offene_wuensche: undefined,
          folgetermine_zu: undefined
        },
        bestaetigung: {
          is_korrekt_analysebogen: false,
          is_korrekt_beratungsprotokoll: false,
          is_hinweis_agb: undefined,
          is_kontaktaufnahme: undefined,
          is_kundeninformation_erhalten: undefined,
          is_sonstige_erhalten: undefined,
          sonstige_detail: undefined,
        }
      },
      products: {
        payment_frequency: 1,
        haftpflicht: {
          is_selected: false,
          input: {
            variant: 'premium_plus',
            begin: undefined,
            end: undefined,
            birthdate: undefined,
            tarif: undefined
          },
          calculation: Object(),
          calculationBE: Object(),
        },
        hausrat: {
          is_selected: false,
          input: {
            variant: 'premium_plus',
            begin: undefined,
            end: undefined,
            wohnflaeche: undefined,
            modules: {
              include_benefit: true,
              include_smarthome: false,
              include_elementar: false,
              include_marktgarantie: true,
              include_glas: false,
              sum_fahrraddiebstahl: undefined
            },
            risk_address: {},
            questions: {
              is_staendig_bewohnt: false,
              is_buendiges_zylinderschloss: false,
              is_anzeigepflichtige_betriebe: false
            }
          },
          calculation: Object(),
          calculationBE: Object(),
        },
        wohngebaeude: {
          is_selected: false,
          input: {
            variant: 'premium_plus',
            begin: undefined,
            end: undefined,
            wohnflaeche: undefined,
            ursprungsbaujahr: undefined,
            modules: {
              include_benefit: true,
              include_marktgarantie: false,
              include_smarthome: false,
              include_elementar: false,
              include_glas: false,
              include_feuerohbau: false
            },
            risk_address: {},
            questions: {
              is_ein_zweifamilienhaus: false,
              is_staendig_bewohnt: false,
              is_denkmalgeschuetzt: false,
              is_weiche_dachung: false,
              is_wochenendhaus: false,
              is_anzeigepflichtige_betriebe: false,
              is_gewerbetriebe: false
            }
          },
          calculation: Object(),
          calculationBE: Object(),
        },
        unfall: {
          is_selected: false,
          input: {
            variant: 'premium_plus',
            type: 'exklusiv',
            begin: undefined,
            end: undefined,
            insured_persons: []
          },
          calculation: Object(),
          calculationBE: Object(),
        }
      },
      calculation: Object(),
      calculationBE: Object(),
    };
    this.process = emptyProcess;

    this.activePathIndex = 0;
    this.activePersonIndex = 0;
    this.navigationPaths = [];
    this.unfallrentenkapitalArr = [];
    this.unfallRenteEuroArr = [];
    this.todesfallArr = [];
    this.krankenHausEuroArr = [];
    this.tagegeldEuroArr = [];
    this.leistungArr = [];
    this.fahrraddiebstahlArr = [];
    this.maxGipsgeld = undefined;
    this.displayedMaxGipsgeld = undefined;
    this.maxTagegeld = undefined;
    this.bezeichnungBeruf = undefined;
    this.allSigned = false;
    this.antragsnummer = undefined;
    this.signatures = [];

    this.tarifierungItems = [
      { name: 'Sach-/Haftpflichtversicherung', isSelected: false, isDivider: true },
      { name: 'Privathaftpflicht', isSelected: false, url: '/haftpflicht' },
      { name: 'Hausrat', isSelected: false, url: '/hausrat' },
      { name: 'Wohngebäude', isSelected: false, url: '/wohngebaude' },
      { name: 'Personenversicherung', isSelected: false, isDivider: true },
      { name: 'Unfall', isSelected: false, url: '/unfall' },
    ];
    this.initArrays();
  }

  isFilled(value: any) {
    return value !== undefined && value !== null;
  }

  initArrays() {
    this.unfallrentenkapitalArr = [];
    this.todesfallArr = [];
    this.krankenHausEuroArr = [];
    this.tagegeldEuroArr = [];
    this.unfallRenteEuroArr = [];
    this.leistungArr = [];
    for (let i = 1; i <= 350; i++) {
      this.unfallrentenkapitalArr.push(i * 1000);
      if (i <= 300) {
        this.todesfallArr.push(i * 1000);
      }
      if (i <= 100) {
        this.krankenHausEuroArr.push(i);
      }
      if (i <= 50) {
        this.tagegeldEuroArr.push(i);
      }
      if (i <= 20) {
        this.unfallRenteEuroArr.push(i * 100);
      }
      if (i <= 10) {
        this.leistungArr.push(i * 100);
      }
    }
  }

  getEmpty(toProcess: any) {
    if (toProcess === null || toProcess === undefined) {
      toProcess = '';
    }
    if (toProcess instanceof String) {
      return toProcess.trim();
    } else {
      return toProcess;
    }
  }

  getInt(toProcess: any) {
    if (toProcess === null || toProcess === undefined || toProcess === '') {
      toProcess = '0';
    }
    return parseInt(toProcess, 10);
  }

  getVersicherungsumfangValidationValues(personIndex: any) {
    this.maxGipsgeld = (this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet_leistung) +
      this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrentenkapital) +
      this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung)) * 0.01;
    this.maxTagegeld = (this.getInt(this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet_leistung) +
      this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrentenkapital)) * 0.0005 +
      this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung)) * 0.05;
    this.maxTagegeld = Math.floor(this.maxTagegeld / 100) * 100;
    if (this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.gipsgeld_leistung) > this.maxGipsgeld) {
      this.displayedMaxGipsgeld = Math.floor(this.maxGipsgeld / 100) * 100;
    }
    const bezeichnungBerufIndex = this.process.products.unfall.input.insured_persons[personIndex].personal_data.arbeitsverhaeltnis;
    this.bezeichnungBeruf = this.unfallDataService.unfallData.arbeitsverhaeltnisse[bezeichnungBerufIndex];
    if (this.bezeichnungBeruf === undefined) {
      this.bezeichnungBeruf = 'nicht angegeben';
    }
  }

  gesundheitValidation(personIndex: any) {
    if (this.process.products.unfall.input.insured_persons[this.activePersonIndex].health &&
      this.process.products.unfall.input.insured_persons[this.activePersonIndex].health.question1 === false &&
      this.process.products.unfall.input.insured_persons[this.activePersonIndex].health.question2 === false &&
      this.process.products.unfall.input.insured_persons[this.activePersonIndex].health.terminated === false) {
      return true;
    } else {
      return false;
    }
  }

  versicherungsumfangValidation(personIndex: any) {
    if (this.process.products.unfall.input.insured_persons[personIndex].scope.is_abweichung_gesetzliche_erbfolge &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.abweichung_gesetzliche_erbfolge_detail) === '') {
      return false;
    }
    if ((this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet) === '' ||
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet_leistung) === '') &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrentenkapital) === '' &&
      (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente) === '' ||
        this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung) === '')) {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet) !== '' &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.invaliditaet_leistung) === '') {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente) !== '' &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung) === '') {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.krankenhaustagegeld) !== '' &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.krankenhaustagegeld_leistung) === '') {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld) !== '' &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld_leistung) === '') {
      return false;
    }

    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld) === 'Tagegeld ab 15. Tag für Selbstständige' &&
      this.bezeichnungBeruf !== 'Selbstständig') {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld) === 'Tagegeld ab 43. Tag für Arbeitnehmer' &&
      this.bezeichnungBeruf === 'Selbstständig') {
      return false;
    }

    if (this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.gipsgeld_leistung) > this.maxGipsgeld) {
      return false;
    }

    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld) !== '' &&
      this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld_leistung) > this.maxTagegeld) {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.tagegeld) !== '' &&
      (this.process.products.unfall.input.insured_persons[personIndex].personal_data.gefahrengruppe === 'k' ||
        this.process.products.unfall.input.insured_persons[personIndex].personal_data.gefahrengruppe === 'j' ||
        this.process.products.unfall.input.insured_persons[personIndex].personal_data.gefahrengruppe === 'se')) {
      return false;
    }
    if (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.gipsgeld_leistung) !== '' &&
      this.process.products.unfall.input.insured_persons[personIndex].personal_data.gefahrengruppe === 'se') {
      return false;
    }
    if (this.process.products.unfall.input.insured_persons[personIndex].personal_data.gefahrengruppe === 'nv') {
      return false;
    }
    if ((this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente) !== '' &&
      this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung) !== '') &&
      (this.getEmpty(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente) !== '' &&
        this.getInt(this.process.products.unfall.input.insured_persons[personIndex].scope.unfallrente_leistung) > 1500)) {
      return false;
    }
    return true;
  }

  getCalculationBE(calculation, cutZeroDigits = false) {
    const calculationBE = {};
    for (const [key, value] of Object.entries(calculation)) {
      let printValue = '';
      if (key.includes('SteuerProz')) {
        printValue = (Number(value) * 100).toLocaleString('de');
      }
      if (key === 'anzZahlungen') {
        switch (this.process.products.payment_frequency) {
          case 1:
            printValue = 'jährlich';
            break;
          case 2:
            printValue = '1/2 jährlich';
            break;
          case 4:
            printValue = '1/4 jährlich';
            break;
          case 12:
            printValue = 'monatlich';
            break;
        }
      }

      if (printValue === '') {
        if (value !== '') {
          printValue = (Number(value) / 100).toLocaleString('de', {
            useGrouping: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + ' €';
          if (cutZeroDigits) {
            if (printValue.endsWith(',00 €')) {
              printValue = (Number(value) / 100).toLocaleString('de', {
                useGrouping: true,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) + ' €';
            }
          }
        }

      }
      if (printValue === 'NaN €') {
        printValue = '';
      }
      calculationBE[key] = printValue;
    }
    return calculationBE;
  }

  getPrintValue(value, cutZeroDigits = false) {
    let printValue = '';
    if (value && value !== '') {
      printValue = (Number(value)).toLocaleString('de', {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + ' €';
      if (cutZeroDigits) {
        if (printValue.endsWith(',00 €')) {
          printValue = (Number(value)).toLocaleString('de', {
            useGrouping: true,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) + ' €';
        }
      }
    }
    if (printValue === 'NaN €') {
      printValue = '';
    }
    return printValue;
  }

  getDateLabel(element) {
    let res = '';
    if (element !== undefined) {
      res = format(parseISO(element), 'dd.MM.yyyy');
    }

    return res;
  }

  getTarifLabel(tarif) {
    switch (tarif) {
      case 'single':
        return 'Single (ohne Kind)';
      case 'familie':
        return 'Familie';
      case 'senior':
        return 'Senioren';
    }
  }

  getTarifVariantLabel(variant) {
    switch (variant) {
      case 'premium':
        return 'Premium';
      case 'premium_plus':
        return 'Premium Plus';
      case 'exklusiv':
        return 'Exklusiv';
      default:
        return variant;
    }
  }

  processVorversicherung(vorversicherung) {
    vorversicherung['kuendigung_durch_vn'] = false;
    vorversicherung['kuendigung_durch_vu'] = false;
    if (vorversicherung.kuendigung_durch === 'versicherer') {
      vorversicherung['kuendigung_durch_vu'] = true;
    }
    if (vorversicherung.kuendigung_durch === 'versicherungsnehmer') {
      vorversicherung['kuendigung_durch_vn'] = true;
    }
    return vorversicherung;
  }

  getStartData() {
    const products = this.process.products;
    const antraege = ['SEPA_Lastschriftmandat_MANAUG', 'Beratungsprotokoll_Privatkunden_MANAUG'];
    const consent_sign_by_mapping_to_set = {};
    const disabled_consents_to_set = [];
    const signByCustomer = {
      salutation: this.process.antrag.customer.salutation,
      name: this.process.antrag.customer.firstname,
      lastname: this.process.antrag.customer.lastname,
      email: this.process.antrag.customer.email
    };
    if (this.process.antrag.sepa.is_abweichender_kontoinhaber) {
      consent_sign_by_mapping_to_set['customer.sig_sepa_provb'] = {
        salutation: this.process.antrag.sepa.abweichender_kontoinhaber.salutation,
        name: this.process.antrag.sepa.abweichender_kontoinhaber.firstname,
        lastname: this.process.antrag.sepa.abweichender_kontoinhaber.lastname,
        email: this.process.antrag.customer.email
      };
      this.process.antrag.sepa['payer'] = this.process.antrag.sepa.abweichender_kontoinhaber;
      // this.process.antrag.sepa['payer']['salutation'] = '';
    } else {
      consent_sign_by_mapping_to_set['customer.sig_sepa_provb'] = signByCustomer;
      this.process.antrag.sepa['payer'] = this.process.antrag.customer;
    }

    this.process.antrag.customer['birthdate_lbl'] = this.getDateLabel(this.process.antrag.customer.birthdate);

    const vorversicherungen = this.process.antrag.vorversicherungen;
    vorversicherungen.haftpflicht['kuendigung_zum_lbl'] = this.getDateLabel(vorversicherungen.haftpflicht.kuendigung_zum);
    vorversicherungen.hausrat['kuendigung_zum_lbl'] = this.getDateLabel(vorversicherungen.hausrat.kuendigung_zum);
    vorversicherungen.wohngebaeude['kuendigung_zum_lbl'] = this.getDateLabel(vorversicherungen.wohngebaeude.kuendigung_zum);
    vorversicherungen.unfall['kuendigung_zum_lbl'] = this.getDateLabel(vorversicherungen.unfall.kuendigung_zum);
    if (products.haftpflicht.is_selected) {
      const input = products.haftpflicht.input;
      products.haftpflicht['selected_lbl'] = this.getTarifVariantLabel(input.variant);
      input['begin_lbl'] = this.getDateLabel(input.begin);
      input['end_lbl'] = this.getDateLabel(input.end);
      input['birthdate_lbl'] = this.getDateLabel(input.birthdate);
      input['tarif_lbl'] = this.getTarifLabel(input.tarif);
      this.process.antrag.vorversicherungen.haftpflicht = this.processVorversicherung(this.process.antrag.vorversicherungen.haftpflicht);
      products.haftpflicht.calculationBE = this.getCalculationBE(products.haftpflicht.calculation);
      if (products.haftpflicht.input.variant === 'premium') {
        antraege.push('Antrag_PHV_Premium_MANAUG');
      } else {
        antraege.push('Antrag_PHV_PremiumPlus_MANAUG');
      }
      consent_sign_by_mapping_to_set['customer.sig_haftpflicht'] = signByCustomer;
    }
    if (products.hausrat.is_selected) {
      const input = products.hausrat.input;
      products.hausrat['selected_lbl'] = this.getTarifVariantLabel(input.variant);
      input['begin_lbl'] = this.getDateLabel(input.begin);
      input['end_lbl'] = this.getDateLabel(input.end);
      if (!input.modules.sum_fahrraddiebstahl) {
        if (products.hausrat.input.variant === 'premium') {
          input.modules.sum_fahrraddiebstahl = 500;
        } else {
          input.modules.sum_fahrraddiebstahl = 1000;
        }
      }
      input.modules['sum_fahrraddiebstahl_lbl'] = input.modules.sum_fahrraddiebstahl?.toLocaleString('de');
      this.process.antrag.vorversicherungen.hausrat = this.processVorversicherung(this.process.antrag.vorversicherungen.hausrat);
      products.hausrat.calculationBE = this.getCalculationBE(products.hausrat.calculation);
      if (products.hausrat.input.variant === 'premium') {
        antraege.push('Antrag_HRV_Premium_MANAUG');
      } else {
        antraege.push('Antrag_HRV_PremiumPlus_MANAUG');
      }
      consent_sign_by_mapping_to_set['customer.sig_hausrat'] = signByCustomer;
    }
    if (products.wohngebaeude.is_selected) {
      const input = products.wohngebaeude.input;
      products.wohngebaeude['selected_lbl'] = this.getTarifVariantLabel(input.variant);
      input['begin_lbl'] = this.getDateLabel(input.begin);
      input['end_lbl'] = this.getDateLabel(input.end);
      this.process.antrag.vorversicherungen.wohngebaeude = this.processVorversicherung(this.process.antrag.vorversicherungen.wohngebaeude);
      products.wohngebaeude.calculationBE = this.getCalculationBE(products.wohngebaeude.calculation);

      if (products.wohngebaeude.input.variant === 'premium') {
        antraege.push('Antrag_WGV_Premium_MANAUG');
      } else {
        antraege.push('Antrag_WGV_PremiumPlus_MANAUG');
      }
      consent_sign_by_mapping_to_set['customer.sig_wohngebaeude'] = signByCustomer;
    }
    if (products.unfall.is_selected) {
      const input = products.unfall.input;
      products.unfall['selected_lbl'] = this.getTarifVariantLabel(input.variant);
      input['begin_lbl'] = this.getDateLabel(input.begin);
      input['end_lbl'] = this.getDateLabel(input.end);
      this.process.antrag.vorversicherungen.unfall = this.processVorversicherung(this.process.antrag.vorversicherungen.unfall);
      products.unfall.calculationBE = this.getCalculationBE(products.unfall.calculation);

      let index = 0;
      for (index = input.insured_persons.length+1; index <7; index++) {
        disabled_consents_to_set.push('customer.sig_beleherung_vp'+index);
        disabled_consents_to_set.push('customer.sig_unfall_vp'+index);
      }
      for (index = 0; index < input.insured_persons.length; index++) {
        input.insured_persons[index].personal_data.birthdate_lbl = this.getDateLabel(input.insured_persons[index].personal_data.birthdate);
        input.insured_persons[index].personal_data.selbstaendig = false;
        input.insured_persons[index].personal_data.oeffentlicher_dienst = false;
        input.insured_persons[index].personal_data.beamter = false;
        input.insured_persons[index].personal_data.azubi = false;
        input.insured_persons[index].personal_data.koerperlich_taetig = false;
        products.unfall.calculation.berechnungsergebnisse[index].beitragZahlVPGemZW_lbl = this.getPrintValue(products.unfall.calculation.berechnungsergebnisse[index].beitragZahlVPGemZW / 100, true);

        switch (input.insured_persons[index].personal_data.art_der_taetigkeit) {
          case '0':
            input.insured_persons[index].personal_data.koerperlich_taetig = true;
            break;
          case '2':
          case '4':
          case '5':
            input.insured_persons[index].personal_data.azubi = true;
            break;
        }

        switch (input.insured_persons[index].personal_data.arbeitsverhaeltnis) {
          case '0':
            input.insured_persons[index].personal_data.selbstaendig = true;
            break;
          case '2':
            input.insured_persons[index].personal_data.oeffentlicher_dienst = true;
            break;
          case '3':
            input.insured_persons[index].personal_data.beamter = true;
            break;
        }

        switch (input.insured_persons[index].scope.invaliditaet) {
          case 'Invalidität mit Mehrleistung 1.200':
            input.insured_persons[index].scope.inv_ml_1200_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
          case 'Invalidität mit Progression 1.000':
            input.insured_persons[index].scope.inv_pg_1000_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
          case 'Invalidität mit Progression 1.200':
            input.insured_persons[index].scope.inv_pg_1200_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
          case 'Invalidität mit Progression 500':
            input.insured_persons[index].scope.inv_pg_500_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
          case 'Invalidität mit Progression 250':
            input.insured_persons[index].scope.inv_pg_250_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
          case 'Invalidität':
            input.insured_persons[index].scope.inv_lbl = this.getPrintValue(input.insured_persons[index].scope.invaliditaet_leistung, true);
            break;
        }
        input.insured_persons[index].scope.unfallrentenkapital_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrentenkapital, true);

        switch (input.insured_persons[index].scope.unfallrente) {
          case 'Unfall-Rente 35/50/90':
            input.insured_persons[index].scope.ur_35_50_90_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
          case 'Unfall-Rente 50/90':
            input.insured_persons[index].scope.ur_50_90_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
          case 'Unfall-Rente 50':
            input.insured_persons[index].scope.ur_50_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
          case 'Unfall-Rente 35/50/90 mit Dynamik 2%':
            input.insured_persons[index].scope.ur_35_50_90_dyn_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
          case 'Unfall-Rente 50/90 mit Dynamik 2%':
            input.insured_persons[index].scope.ur_50_90_dyn_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
          case 'Unfall-Rente 50 mit Dynamik 2%':
            input.insured_persons[index].scope.ur_50_dyn_lbl = this.getPrintValue(input.insured_persons[index].scope.unfallrente_leistung, true);
            break;
        }
        input.insured_persons[index].scope.todesfall_leistung_lbl = this.getPrintValue(input.insured_persons[index].scope.todesfall_leistung, true);

        switch (input.insured_persons[index].scope.krankenhaustagegeld) {
          case 'Krankenhaustagegeld':
            input.insured_persons[index].scope.ktg_lbl = this.getPrintValue(input.insured_persons[index].scope.krankenhaustagegeld_leistung, true);
            break;
          case 'Krankenhaustagegeld mit Genesungsgeld':
            input.insured_persons[index].scope.gg_lbl = this.getPrintValue(input.insured_persons[index].scope.krankenhaustagegeld_leistung, true);
            break;
        }

        switch (input.insured_persons[index].scope.tagegeld) {
          case 'Krankenhaustagegeld':
            input.insured_persons[index].scope.tg_15_lbl = this.getPrintValue(input.insured_persons[index].scope.tagegeld_leistung, true);
            break;
          case 'Krankenhaustagegeld mit Genesungsgeld':
            input.insured_persons[index].scope.tg_43_lbl = this.getPrintValue(input.insured_persons[index].scope.tagegeld_leistung, true);
            break;
        }
        input.insured_persons[index].scope.gipsgeld_leistung_lbl = this.getPrintValue(input.insured_persons[index].scope.gipsgeld_leistung, true);

        const signByVP = {
          salutation: input.insured_persons[index].personal_data.salutation,
          name: input.insured_persons[index].personal_data.firstname,
          lastname: input.insured_persons[index].personal_data.lastname,
          email: this.process.antrag.customer.email
        };

        consent_sign_by_mapping_to_set['customer.sig_beleherung_vp' + (index + 1)] = signByVP;
        consent_sign_by_mapping_to_set['customer.sig_unfall_vp' + (index + 1)] = signByVP;

      }

      if (products.unfall.input.variant === 'premium') {
        antraege.push('Antrag_UNV_Premium_MANAUG');
      } else {
        antraege.push('Antrag_UNV_Exklusiv_MANAUG');
      }
      consent_sign_by_mapping_to_set['customer.sig_unfall'] = signByCustomer;
    }
    const startData = {
      id_user: localStorage.getItem('userId'),
      id_customer_foreign: '4711',
      consent_sign_by_mapping: consent_sign_by_mapping_to_set,
      disabled_consents: disabled_consents_to_set,
      email: this.process.antrag.customer.email,
      salutation: this.process.antrag.customer.salutation,
      name: this.process.antrag.customer.firstname,
      lastname: this.process.antrag.customer.lastname,
      auth: 'email',
      templates: antraege,
      custom: this.process
    };
    //console.log('startData', startData);
    return startData;
  }

  getStoreData() {
    const vorschlaege = [];
    const products = this.process.products;
    if (products.haftpflicht.is_selected) {
      if (products.haftpflicht.input.variant === 'premium') {
        vorschlaege.push('Vorschlag_PHV_Premium_MANAUG');
      } else {
        vorschlaege.push('Vorschlag_PHV_PremiumPlus_MANAUG');
      }
    }
    if (products.hausrat.is_selected) {
      if (products.hausrat.input.variant === 'premium') {
        vorschlaege.push('Vorschlag_HRV_Premium_MANAUG');
      } else {
        vorschlaege.push('Vorschlag_HRV_PremiumPlus_MANAUG');
      }
    }
    if (products.wohngebaeude.is_selected) {
      if (products.wohngebaeude.input.variant === 'premium') {
        vorschlaege.push('Vorschlag_WGV_Premium_MANAUG');
      } else {
        vorschlaege.push('Vorschlag_WGV_PremiumPlus_MANAUG');
      }
    }
    if (products.unfall.is_selected) {
      if (products.unfall.input.variant === 'premium') {
        vorschlaege.push('Vorschlag_UNV_Premium_MANAUG');
      } else {
        vorschlaege.push('Vorschlag_UNV_Exklusiv_MANAUG');
      }
    }
    const storeData = this.getStartData();
    storeData['templates'] = vorschlaege;
    return storeData;
  }


}
