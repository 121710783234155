/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ZuersService } from 'src/app/services/zuers.service';
import { AddressModalComponent } from '../address-modal/address-modal.component';

@Component({
  selector: 'app-address-fields',
  templateUrl: './address-fields.component.html',
  styleUrls: ['./address-fields.component.scss'],
})
export class AddressFieldsComponent {

  @ViewChild('zipInput') zipInput: IonInput;
  @ViewChild('streetInput') streetInput: IonInput;

  showRestOfFields = false;
  streets = undefined;
  streetFromService = '';
  zipValid = '';
  streetValid = '';
  housenumberValid = '';

  constructor(public cs: ContextService, private zuers: ZuersService, public modalCtrl: ModalController, public vs: ValidationService) { }

  validateFormField(fieldName: string) {
    if (fieldName === 'zip') {
      this.zipValid = this.vs.validatePlz(this.cs.process.risk_address.zip);
    }
    if (fieldName === 'street') {
      this.streetValid = this.vs.validateField(this.cs.process.risk_address.street);
    }
    if (fieldName === 'housenumber') {
      this.housenumberValid = this.vs.validateField(this.cs.process.risk_address.housenumber);
    }
  }

  async getAddresses() {
    if (this.streetFromService !== this.cs.process.risk_address.street) {
      const zipInputEL = await this.zipInput.getInputElement();
      const streetInputEL = await this.streetInput.getInputElement();
      if (String(this.cs.process.risk_address.zip)?.length === 5 && this.cs.process.risk_address.street?.length >= 2) {
        const res = await this.zuers.getAddress(this.cs.process.risk_address.zip, this.cs.process.risk_address.street);
        this.streets = res['streets'];
        if (this.streets && this.streets.length > 1) {
          this.showRestOfFields = true;
          this.zipValid = '';
          this.streetValid = '';
          zipInputEL.blur();
          streetInputEL.blur();
          await this.presentModal();
        } else if (this.streets) {
          this.showRestOfFields = true;
          this.streetFromService = this.streets.strasse;
          this.cs.process.risk_address.street = this.streets.Strasse;
          this.cs.process.risk_address.city = this.streets.Ort;
          this.cs.process.risk_address.district = this.streets.Ortsteil;
          this.zipValid = '';
          this.streetValid = '';
          this.housenumberValid = '';
          zipInputEL.blur();
          streetInputEL.blur();
        } else {
          this.showRestOfFields = false;
          this.streets = null;
          this.streetFromService = '';
          this.cs.process.risk_address.housenumber = '';
        }
      } else {
        this.showRestOfFields = false;
        this.streets = null;
        this.cs.process.risk_address.housenumber = '';
      }
      //console.log('ZIP Valid', this.zipValid);
    }
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AddressModalComponent,
      componentProps: {
        streets: this.streets,
      }
    });

    modal.onDidDismiss()
      .then((data) => {
        const address = data.data['selectedAddress'];
        this.streetFromService = address.Strasse;
        this.cs.process.risk_address.street = address.Strasse;
        this.cs.process.risk_address.city = address.Ort;
        this.cs.process.risk_address.district = address.Ortsteil;
      });

    return await modal.present();
  }

}
