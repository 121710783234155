import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class NotificationService {

  birthdateChanged = new EventEmitter<any>();

  constructor() {
  }
}
