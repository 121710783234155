/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ContextService } from '../context.service';
import { HaftpflichtDataService } from '../tarif-data/haftpflicht-data.service';

@Injectable({
  providedIn: 'root'
})
export class HaftpflichtService {

  constructor(private cs: ContextService, public haftpflichtDataService: HaftpflichtDataService) { }

  getCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifData = this.haftpflichtDataService.haftpflichtData;
    const tarifKey = this.cs.process.products.haftpflicht.input.variant;
    const gruppeId = tarifData.tarife[tarifKey].gruppen[this.cs.process.products.haftpflicht.input.tarif];
    const result = Object();
    let zuschlaege = 0;
    let anzZahlungen_lbl = 'jährlich';
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        anzZahlungen_lbl = 'jährlich';
        break;
      case 2:
        zuschlaege += 3;
        anzZahlungen_lbl = '1/2 jährlich';
        break;
      case 4:
        zuschlaege += 5;
        anzZahlungen_lbl = '1/4 jährlich';
        break;
      case 12:
        zuschlaege += 5;
        anzZahlungen_lbl = 'monatlich';
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.gruppe = gruppeId;
    result.steuersatz = tarifData.taxRate;

    result.haftpflichtNetto = gruppeId.jnp * (1 + zuschlaege / 100);
    result.haftpflichtNetto = Math.round(result.haftpflichtNetto / zahlweise) * zahlweise;
    result.haftpflichtVersicherungssteuer = Math.round(result.haftpflichtNetto * result.steuersatz);
    result.haftpflichtVersicherungssteuer = Math.round(result.haftpflichtVersicherungssteuer / zahlweise) * zahlweise;
    result.haftpflichtZahl = result.haftpflichtNetto + result.haftpflichtVersicherungssteuer;
    result.haftpflichtZahlGemZW = result.haftpflichtZahl / zahlweise;
    result.haftpflichtSteuerProz = tarifData.taxRate;

    result.gesamtNetto = result.haftpflichtNetto;
    result.gesamtVersicherungssteuer = result.haftpflichtVersicherungssteuer;
    result.gesamtZahl = result.haftpflichtZahl;
    result.gesamtZahlGemZW = result.haftpflichtZahlGemZW;


    result.hauptNetto = result.haftpflichtNetto;
    result.hauptVersicherungssteuer = result.haftpflichtVersicherungssteuer;
    result.hauptZahl = result.haftpflichtZahl;
    result.hauptZahlGemZW = result.haftpflichtZahlGemZW;
    result.hauptSteuerProz = result.steuersatz;
    /*
    const resultBE = Object();
    resultBE.anzZahlungen_lbl = anzZahlungen_lbl;
    resultBE.beitragGesamtNetto = (result.beitragGesamtNetto / 100).toLocaleString('de', { minimumFractionDigits: 2 });
    resultBE.beitragGesamtVersicherungssteuer = (result.beitragGesamtVersicherungssteuer / 100).toLocaleString('de', { minimumFractionDigits: 2 });
    resultBE.beitragGesamtZahl = (result.beitragGesamtZahl / 100).toLocaleString('de', { minimumFractionDigits: 2 });
    resultBE.beitragGesamtZahlGemZW = (result.beitragGesamtZahlGemZW / 100).toLocaleString('de', { minimumFractionDigits: 2 });
*/

    result.haftpflichtSteuerProz = this.haftpflichtDataService.haftpflichtData.taxRate;

    this.cs.process.products.haftpflicht.calculation = { ...result };
    //this.cs.process.products.haftpflicht.calculationBE = { ...resultBE };
  }
}
