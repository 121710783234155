/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HaftpflichtDataService {

  haftpflichtData = {
    version: '202201121122261452597746',
    taxRate: 0.19,
    product: {
      name: 'Haftpflichtversicherung',
      short: 'Haftpflicht'
    },
    tarife: {
      premium: {
        name: 'Premium',
        gruppen: {
          familie: {
            name: 'Familien',
            jnp: 5684
          },
          single: {
            name: 'Single',
            jnp: 3084
          },
          senior: {
            name: 'Senioren',
            jnp: 2640
          }
        }
      },

      premium_plus: {
        name: 'Premium-Plus',
        gruppen: {
          familie: {
            name: 'Familien',
            jnp: 7992
          },
          single: {
            name: 'Single',
            jnp: 5786
          },
          senior: {
            name: 'Senioren',
            jnp: 4682
          }
        }
      }
    }
  };

  constructor() { }
}
