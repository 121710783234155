/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HausratDataService {

  glasData = {
    version: '20220228',

    product: {
      name: 'Glasversicherung',
      short: 'Glas'
    },

    taxRate: 0.19,

    tarife: {
      premium: {
        name: 'Premium',
        perqm: 25.04
      },
      premium_plus: {
        name: 'Premium Plus',
        perqm: 36.35
      }
    }
  };

  hausratData = {
    version: '20220228',
    taxRate: 0.1615,
    taxRateElementar: 0.1615,
    product: {
      name: 'Hausratversicherung',
      short: 'Hausrat'
    },
    tarife: {
      premium: {
        name: 'Premium',
        benefit: 615,
        smart_home: 3300,
        tarifzonen: {
          1: 51.59,
          2: 55.90,
          3: 65.83,
          4: 89.64,
          5: 106.25,
          6: 121.16
        },
        tarifzonenFahrraddiebstahl: {
          1: {
            1000: 32.12,
            2000: 127.31,
            3000: 218.08,
            4000: 307.31,
            5000: 394.62,
            6000: 478.27,
            7000: 562.31,
            8000: 644.42,
            9000: 724.81,
            10000: 803.65,
          },
          2: {
            1000: 54.42,
            2000: 216.35,
            3000: 370.77,
            4000: 522.31,
            5000: 670.96,
            6000: 813.08,
            7000: 955.77,
            8000: 1095.58,
            9000: 1232.31,
            10000: 1366.15,
          },
          3: {
            1000: 64.04,
            2000: 254.42,
            3000: 436.15,
            4000: 614.62,
            5000: 789.42,
            6000: 956.54,
            7000: 1124.42,
            8000: 1288.85,
            9000: 1449.81,
            10000: 1607.31,
          },
          4: {
            1000: 64.04,
            2000: 254.42,
            3000: 436.15,
            4000: 614.62,
            5000: 789.42,
            6000: 956.54,
            7000: 1124.42,
            8000: 1288.85,
            9000: 1449.81,
            10000: 1607.31,
          },
          5: {
            1000: 68.85,
            2000: 273.46,
            3000: 469.04,
            4000: 660.58,
            5000: 848.65,
            6000: 1028.46,
            7000: 1208.85,
            8000: 1385.58,
            9000: 1558.46,
            10000: 1727.88,
          },
          6: {
            1000: 68.85,
            2000: 273.46,
            3000: 469.04,
            4000: 660.58,
            5000: 848.65,
            6000: 1028.46,
            7000: 1208.85,
            8000: 1385.58,
            9000: 1558.46,
            10000: 1727.88,
          }
        },
        zuersZonen: {
          1: 20.60,
          2: 58.33,
          3: 265.04
        }
      },
      premium_plus: {
        name: 'Premium Plus',
        benefit: 615,
        smart_home: 3300,
        tarifzonen: {
          1: 55.77,
          2: 57.69,
          3: 72.94,
          4: 96.90,
          5: 114.87,
          6: 130.98
        },
        tarifzonenFahrraddiebstahl: {
          1: {
            2000: 95.16,
            3000: 186.05,
            4000: 275.20,
            5000: 362.61,
            6000: 446.24,
            7000: 530.18,
            8000: 612.37,
            9000: 692.83,
            10000: 771.55,
          },
          2: {
            2000: 161.78,
            3000: 316.29,
            4000: 467.85,
            5000: 616.46,
            6000: 758.63,
            7000: 901.32,
            8000: 1041.06,
            9000: 1177.84,
            10000: 1311.66,
          },
          3: {
            2000: 190.32,
            3000: 372.10,
            4000: 550.40,
            5000: 725.23,
            6000: 892.48,
            7000: 1060.35,
            8000: 1224.75,
            9000: 1385.66,
            10000: 1543.10,
          },
          4: {
            2000: 190.32,
            3000: 372.10,
            4000: 550.40,
            5000: 725.23,
            6000: 892.48,
            7000: 1060.35,
            8000: 1224.75,
            9000: 1385.66,
            10000: 1543.10,
          },
          5: {
            2000: 204.60,
            3000: 400.02,
            4000: 591.70,
            5000: 779.64,
            6000: 959.44,
            7000: 1139.90,
            8000: 1316.63,
            9000: 1489.62,
            10000: 1658.87,
          },
          6: {
            2000: 204.60,
            3000: 400.02,
            4000: 591.70,
            5000: 779.64,
            6000: 959.44,
            7000: 1139.90,
            8000: 1316.63,
            9000: 1489.62,
            10000: 1658.87,
          }
        },
        zuersZonen: {
          1: 20.60,
          2: 58.33,
          3: 265.04
        }
      }
    },
  };

  constructor() { }
}
