/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tarifierung',
    loadChildren: () => import('./pages/tarifierung/tarifierung.module').then( m => m.TarifierungPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'angebot-scannen',
    loadChildren: () => import('./pages/angebot-scannen/angebot-scannen.module').then( m => m.AngebotScannenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'antrag-erfassen',
    loadChildren: () => import('./pages/antrag-erfassen/antrag-erfassen.module').then( m => m.AntragErfassenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./pages/einstellungen/einstellungen.module').then( m => m.EinstellungenPageModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./pages/kontakt/kontakt.module').then( m => m.KontaktPageModule)
  },
  {
    path: 'haftpflicht',
    loadChildren: () => import('./pages/tarifs/haftpflicht/haftpflicht.module').then( m => m.HaftpflichtPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hausrat',
    loadChildren: () => import('./pages/tarifs/hausrat/hausrat.module').then( m => m.HausratPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wohngebaude',
    loadChildren: () => import('./pages/tarifs/wohngebaude/wohngebaude.module').then( m => m.WohngebaudePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'unfall',
    loadChildren: () => import('./pages/tarifs/unfall/unfall.module').then( m => m.UnfallPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pramienberechnung',
    loadChildren: () => import('./pages/pramienberechnung/pramienberechnung.module').then( m => m.PramienberechnungPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'antrag',
    loadChildren: () => import('./pages/antrag/antrag.module').then( m => m.AntragPageModule)
  },
  {
    path: 'advice-call',
    loadChildren: () => import('./pages/advice/advice-call/advice-call.module').then( m => m.AdviceCallPageModule)
  },
  {
    path: 'advice-confirmation',
    loadChildren: () => import('./pages/advice/advice-confirmation/advice-confirmation.module').then( m => m.AdviceConfirmationPageModule)
  },
  {
    path: 'person-daten',
    loadChildren: () => import('./pages/unfall-pages/person-daten/person-daten.module').then( m => m.PersonDatenPageModule)
  },
  {
    path: 'person-beruf',
    loadChildren: () => import('./pages/unfall-pages/person-beruf/person-beruf.module').then( m => m.PersonBerufPageModule)
  },
  {
    path: 'person-uebersicht',
    loadChildren: () => import('./pages/unfall-pages/person-uebersicht/person-uebersicht.module').then( m => m.PersonUebersichtPageModule)
  },
  {
    path: 'person-gesundheit',
    loadChildren: () => import('./pages/unfall-pages/person-gesundheit/person-gesundheit.module').then( m => m.PersonGesundheitPageModule)
  },
  {
    path: 'person-versicherungsumfang',
    loadChildren: () => import('./pages/unfall-pages/person-versicherungsumfang/person-versicherungsumfang.module').then( m => m.PersonVersicherungsumfangPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'process-signatures',
    loadChildren: () => import('./pages/process-signatures/process-signatures.module').then( m => m.ProcessSignaturesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
