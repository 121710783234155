/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WohngebaeudeDataService {

  glasData = {
    version: '20220228',

    product: {
      name: 'Glasversicherung',
      short: 'Glas'
    },

    taxRate: 0.19,

    tarife: {
      premium: {
        name: 'Premium',
        perqm: 25.04
      },
      premium_plus: {
        name: 'Premium Plus',
        perqm: 36.35
      }
    }
  };

  wohngebaeudeData = {
    version: '20220228',
    taxRate: 0.1634,
    taxRateElementar: 0.1634,
    product: {
      name: 'Wohngebäudeversicherung',
      short: 'Wohngebäude'
    },
    alter: {
      neu: 'Neubau',
      ab8: 'ab 8 Jahren',
      ab17: 'ab 17 Jahren'
    },
    tarife: {
      premium: {
        name: 'Premium',
        benefit: 581,
        smart_home: 6379,
        zonen: {
          neu: {
            '1/1': 96.30,
            '2/1': 112.65,
            '3/1': 136.28,
            '4/1': 156.27,
            '1/2': 116.29,
            '2/2': 132.64,
            '3/2': 150.82,
            '4/2': 176.26,
          },
          ab8: {
            '1/1': 120.38,
            '2/1': 140.83,
            '3/1': 170.35,
            '4/1': 195.35,
            '1/2': 145.37,
            '2/2': 165.83,
            '3/2': 188.52,
            '4/2': 220.33,
          },
          ab17: {
            '1/1': 147.46,
            '2/1': 172.50,
            '3/1': 208.69,
            '4/1': 239.28,
            '1/2': 178.08,
            '2/2': 203.11,
            '3/2': 230.94,
            '4/2': 269.89
          }
        },
        zuersZonen: {
          1: 54.29,
          2: 218.27,
          3: 960.00
        }
      },
      premium_plus: {
        name: 'Premium Plus',
        benefit: 581,
        smart_home: 6379,
        zonen_ohne_ug_mg: {
          neu: {
            '1/1': 121.15,
            '2/1': 141.35,
            '3/1': 143.37,
            '4/1': 163.56,
            '1/2': 169.62,
            '2/2': 189.81,
            '3/2': 195.87,
            '4/2': 214.04,
          },
          ab8: {
            '1/1': 151.44,
            '2/1': 175.67,
            '3/1': 181.73,
            '4/1': 205.96,
            '1/2': 212.02,
            '2/2': 238.27,
            '3/2': 244.33,
            '4/2': 268.56,
          },
          ab17: {
            '1/1': 185.77,
            '2/1': 216.06,
            '3/1': 222.12,
            '4/1': 252.40,
            '1/2': 260.48,
            '2/2': 292.79,
            '3/2': 298.85,
            '4/2': 329.13,
          }
        },
        zonen: {
          neu: {
            '1/1': 132.69,
            '2/1': 154.81,
            '3/1': 157.02,
            '4/1': 179.13,
            '1/2': 185.77,
            '2/2': 207.88,
            '3/2': 214.52,
            '4/2': 234.42,
          },
          ab8: {
            '1/1': 165.87,
            '2/1': 192.40,
            '3/1': 199.04,
            '4/1': 225.58,
            '1/2': 232.21,
            '2/2': 260.96,
            '3/2': 267.60,
            '4/2': 294.13,
          },
          ab17: {
            '1/1': 203.46,
            '2/1': 236.63,
            '3/1': 243.27,
            '4/1': 276.44,
            '1/2': 285.29,
            '2/2': 320.67,
            '3/2': 327.31,
            '4/2': 360.48,
          }
        },
        zuersZonen: {
          1: 54.29,
          2: 218.27,
          3: 960.00
        }
      }
    },
    /*
    tarifzonen: [
      ['01067', '03253', 1, 1],
      ['04103', '04357', 2, 1],
      ['04416', '09669', 1, 1],
      ['10115', '10439', 2, 1],
      ['10551', '12169', 3, 1],
      ['12203', '12489', 2, 1],
      ['12524', '13189', 1, 1],
      ['13347', '13359', 3, 1],
      ['13403', '13629', 2, 1],
      ['14050', '14199', 3, 1],
      ['14467', '18299', 1, 1],
      ['18311', '18519', 1, 2],
      ['18528', '18609', 2, 2],
      ['19053', '19417', 1, 1],
      ['20095', '20539', 4, 1],
      ['21029', '21149', 3, 1],
      ['21217', '21279', 2, 1],
      ['21335', '21371', 1, 1],
      ['21376', '21376', 2, 1],
      ['21379', '21394', 1, 1],
      ['21395', '21395', 2, 1],
      ['21397', '21409', 1, 1],
      ['21423', '21445', 2, 1],
      ['21447', '21449', 1, 1],
      ['21465', '21521', 2, 1],
      ['21522', '21522', 1, 1],
      ['21524', '21529', 2, 1],
      ['21614', '21614', 2, 2],
      ['21629', '21629', 2, 1],
      ['21635', '21644', 2, 2],
      ['21646', '21649', 2, 1],
      ['21680', '21789', 2, 2],
      ['22041', '22089', 4, 1],
      ['22111', '22179', 2, 1],
      ['22297', '22299', 4, 1],
      ['22301', '22459', 3, 1],
      ['22523', '22769', 4, 1],
      ['22844', '22851', 2, 2],
      ['22869', '22969', 2, 1],
      ['23552', '23570', 2, 2],
      ['23611', '23617', 3, 2],
      ['23619', '23619', 2, 1],
      ['23623', '23626', 3, 2],
      ['23627', '23628', 2, 1],
      ['23629', '23717', 3, 2],
      ['23719', '23719', 2, 2],
      ['23730', '23779', 3, 2],
      ['23795', '23829', 2, 2],
      ['23843', '23843', 2, 1],
      ['23845', '23845', 2, 2],
      ['23847', '23863', 2, 1],
      ['23866', '23867', 2, 2],
      ['23869', '23919', 2, 1],
      ['23923', '23999', 1, 1],
      ['24103', '24999', 2, 2],
      ['25335', '25337', 2, 1],
      ['25348', '25348', 2, 2],
      ['25355', '25355', 2, 1],
      ['25358', '25361', 2, 2],
      ['25364', '25365', 2, 1],
      ['25368', '25368', 2, 2],
      ['25370', '25373', 2, 1],
      ['25376', '25379', 2, 2],
      ['25421', '25474', 2, 1],
      ['25479', '25479', 2, 2],
      ['25482', '25485', 2, 1],
      ['25486', '25486', 2, 2],
      ['25488', '25499', 2, 1],
      ['25524', '25524', 2, 2],
      ['25541', '25541', 3, 2],
      ['25548', '25599', 2, 2],
      ['25693', '25867', 3, 2],
      ['25868', '25868', 2, 2],
      ['25869', '25878', 3, 2],
      ['25879', '25879', 2, 2],
      ['25881', '25999', 3, 2],
      ['26121', '26489', 2, 2],
      ['26506', '26553', 3, 2],
      ['26556', '26556', 2, 2],
      ['26571', '26639', 3, 2],
      ['26655', '26689', 2, 2],
      ['26721', '26736', 3, 2],
      ['26757', '26757', 2, 2],
      ['26759', '26759', 3, 2],
      ['26789', '26849', 2, 2],
      ['26871', '26909', 3, 2],
      ['26919', '27333', 2, 2],
      ['27336', '27336', 2, 1],
      ['27337', '27478', 2, 2],
      ['27498', '27498', 2, 1],
      ['27499', '27499', 2, 2],
      ['27568', '27580', 3, 2],
      ['27607', '27639', 2, 2],
      ['27711', '27729', 3, 2],
      ['27749', '27809', 2, 2],
      ['28195', '28279', 4, 2],
      ['28307', '28790', 3, 2],
      ['28816', '28857', 2, 2],
      ['28865', '28865', 3, 2],
      ['28870', '28876', 2, 2],
      ['28879', '28879', 3, 2],
      ['29221', '29364', 2, 1],
      ['29365', '29386', 1, 1],
      ['29389', '29389', 2, 1],
      ['29392', '29393', 1, 1],
      ['29394', '29394', 2, 1],
      ['29396', '29499', 1, 1],
      ['29525', '29699', 2, 1],
      ['30159', '30459', 3, 1],
      ['30519', '31008', 2, 1],
      ['31020', '31020', 3, 1],
      ['31028', '31061', 2, 1],
      ['31073', '31073', 3, 1],
      ['31079', '31199', 2, 1],
      ['31224', '31249', 3, 1],
      ['31275', '31535', 2, 1],
      ['31542', '31542', 3, 1],
      ['31547', '31547', 2, 2],
      ['31552', '31559', 3, 1],
      ['31582', '31638', 2, 2],
      ['31655', '31812', 3, 1],
      ['31832', '31832', 2, 1],
      ['31840', '31868', 3, 1],
      ['32049', '32052', 3, 2],
      ['32105', '32108', 3, 1],
      ['32120', '32609', 3, 2],
      ['32657', '33039', 3, 1],
      ['33098', '33184', 2, 1],
      ['33189', '33189', 3, 1],
      ['33330', '33449', 2, 2],
      ['33602', '33739', 3, 2],
      ['33758', '33803', 2, 2],
      ['33813', '33818', 3, 1],
      ['33824', '33829', 2, 2],
      ['34117', '34134', 4, 1],
      ['34212', '34212', 3, 1],
      ['34225', '34277', 4, 1],
      ['34281', '34286', 3, 1],
      ['34289', '34292', 4, 1],
      ['34295', '34295', 3, 1],
      ['34298', '34298', 4, 1],
      ['34302', '34305', 3, 1],
      ['34308', '34320', 4, 1],
      ['34323', '34327', 3, 1],
      ['34329', '34329', 4, 1],
      ['34346', '34355', 3, 1],
      ['34359', '34399', 4, 1],
      ['34414', '34439', 3, 1],
      ['34454', '34549', 4, 1],
      ['34560', '35043', 3, 1],
      ['35066', '35066', 4, 1],
      ['35075', '35085', 3, 1],
      ['35088', '35088', 4, 1],
      ['35091', '35096', 3, 1],
      ['35099', '35099', 4, 1],
      ['35102', '35102', 3, 1],
      ['35104', '35110', 4, 1],
      ['35112', '35112', 3, 1],
      ['35114', '35116', 4, 1],
      ['35117', '35117', 3, 1],
      ['35119', '35119', 4, 1],
      ['35216', '35282', 3, 1],
      ['35285', '35285', 4, 1],
      ['35287', '35288', 3, 1],
      ['35305', '35469', 2, 1],
      ['35510', '35519', 3, 1],
      ['35576', '35716', 2, 1],
      ['35719', '35719', 3, 1],
      ['35745', '35768', 2, 1],
      ['35781', '36103', 3, 1],
      ['36110', '36110', 2, 1],
      ['36115', '36199', 3, 1],
      ['36205', '36205', 4, 1],
      ['36208', '36289', 3, 1],
      ['36304', '36358', 2, 1],
      ['36364', '36364', 3, 1],
      ['36367', '36369', 2, 1],
      ['36381', '36396', 4, 1],
      ['36399', '36399', 2, 1],
      ['36404', '36469', 1, 1],
      ['37073', '37199', 3, 1],
      ['37213', '37299', 4, 1],
      ['37308', '37359', 1, 1],
      ['37412', '37699', 3, 1],
      ['38100', '38154', 2, 1],
      ['38159', '38162', 3, 1],
      ['38165', '38165', 2, 1],
      ['38170', '38176', 3, 1],
      ['38179', '38179', 1, 1],
      ['38226', '38329', 3, 1],
      ['38350', '38388', 2, 1],
      ['38440', '38448', 1, 1],
      ['38458', '38464', 2, 1],
      ['38465', '38559', 1, 1],
      ['38640', '38729', 3, 1],
      ['38820', '39649', 1, 1],
      ['40210', '40699', 4, 2],
      ['40721', '40789', 3, 2],
      ['40822', '41239', 4, 2],
      ['41334', '41334', 3, 2],
      ['41352', '41363', 4, 2],
      ['41366', '41379', 3, 2],
      ['41460', '41569', 4, 2],
      ['41747', '42579', 3, 2],
      ['42651', '42719', 2, 2],
      ['42781', '42929', 3, 2],
      ['44135', '44149', 4, 2],
      ['44225', '44289', 3, 2],
      ['44309', '44536', 2, 2],
      ['44575', '45359', 3, 2],
      ['45468', '45481', 4, 2],
      ['45525', '45968', 3, 2],
      ['46045', '46149', 4, 2],
      ['46236', '46459', 3, 2],
      ['46483', '47506', 4, 2],
      ['47509', '47661', 3, 2],
      ['47665', '47665', 4, 2],
      ['47669', '47669', 3, 2],
      ['47798', '47839', 4, 2],
      ['47877', '47929', 3, 2],
      ['48143', '48167', 4, 2],
      ['48231', '48249', 2, 2],
      ['48268', '48282', 3, 2],
      ['48291', '48336', 2, 2],
      ['48341', '48341', 3, 2],
      ['48346', '48351', 2, 2],
      ['48356', '48356', 3, 2],
      ['48361', '48361', 2, 2],
      ['48366', '48629', 3, 2],
      ['48653', '48653', 2, 2],
      ['48683', '48712', 3, 2],
      ['48720', '48727', 2, 2],
      ['48734', '49328', 3, 2],
      ['49356', '49356', 2, 2],
      ['49377', '49401', 3, 2],
      ['49406', '49406', 2, 2],
      ['49413', '49413', 3, 2],
      ['49419', '49419', 2, 2],
      ['49424', '49439', 3, 2],
      ['49448', '49448', 2, 2],
      ['49451', '49451', 3, 2],
      ['49453', '49453', 2, 2],
      ['49456', '49456', 3, 2],
      ['49457', '49459', 2, 2],
      ['49477', '49610', 3, 2],
      ['49624', '49624', 2, 2],
      ['49626', '49626', 3, 2],
      ['49632', '49632', 2, 2],
      ['49635', '49638', 3, 2],
      ['49661', '49699', 2, 2],
      ['49716', '49849', 3, 2],
      ['50126', '51149', 4, 2],
      ['51371', '51597', 3, 2],
      ['51598', '51598', 3, 1],
      ['51643', '53534', 3, 2],
      ['53539', '53539', 2, 2],
      ['53545', '53579', 3, 1],
      ['53604', '53604', 3, 2],
      ['53619', '53619', 3, 1],
      ['53639', '54346', 3, 2],
      ['54347', '54349', 2, 2],
      ['54411', '54422', 3, 2],
      ['54424', '54426', 2, 2],
      ['54427', '54459', 3, 2],
      ['54470', '54528', 2, 2],
      ['54529', '54529', 3, 2],
      ['54531', '54589', 2, 2],
      ['54595', '54610', 3, 2],
      ['54611', '54611', 2, 2],
      ['54612', '54689', 3, 2],
      ['55116', '55131', 4, 1],
      ['55218', '55425', 3, 1],
      ['55430', '55432', 2, 1],
      ['55435', '55437', 3, 1],
      ['55442', '55452', 4, 1],
      ['55457', '55459', 3, 1],
      ['55469', '55499', 2, 1],
      ['55543', '55571', 4, 1],
      ['55576', '55578', 3, 1],
      ['55583', '55596', 4, 1],
      ['55597', '55599', 3, 1],
      ['55606', '55606', 4, 1],
      ['55608', '55608', 2, 2],
      ['55618', '55621', 4, 1],
      ['55624', '55626', 2, 2],
      ['55627', '55629', 4, 1],
      ['55743', '55779', 2, 2],
      ['56068', '56077', 4, 1],
      ['56112', '56133', 3, 1],
      ['56154', '56154', 2, 1],
      ['56170', '56191', 3, 1],
      ['56203', '56206', 2, 1],
      ['56218', '56220', 3, 1],
      ['56235', '56254', 2, 1],
      ['56269', '56276', 3, 1],
      ['56281', '56291', 2, 1],
      ['56294', '56323', 3, 1],
      ['56329', '56329', 2, 1],
      ['56330', '56333', 3, 1],
      ['56335', '56337', 2, 1],
      ['56338', '56379', 3, 1],
      ['56410', '56479', 2, 1],
      ['56564', '56648', 3, 1],
      ['56651', '56659', 3, 2],
      ['56727', '56745', 3, 1],
      ['56746', '56746', 3, 2],
      ['56751', '56753', 3, 1],
      ['56754', '56766', 2, 1],
      ['56767', '56769', 2, 2],
      ['56812', '56829', 2, 1],
      ['56841', '56850', 2, 2],
      ['56856', '56859', 2, 1],
      ['56861', '56861', 2, 2],
      ['56862', '56869', 2, 1],
      ['57072', '57339', 3, 1],
      ['57368', '57368', 4, 1],
      ['57392', '57392', 3, 1],
      ['57399', '57489', 4, 1],
      ['57518', '57614', 3, 1],
      ['57627', '57629', 2, 1],
      ['57632', '57641', 3, 1],
      ['57642', '57648', 2, 1],
      ['58089', '58135', 3, 2],
      ['58239', '58239', 2, 2],
      ['58256', '58710', 3, 2],
      ['58730', '58739', 2, 2],
      ['58762', '58849', 3, 2],
      ['59063', '59609', 2, 2],
      ['59755', '61118', 3, 1],
      ['61130', '61138', 4, 1],
      ['61169', '61479', 3, 1],
      ['63065', '63075', 4, 1],
      ['63110', '63329', 3, 1],
      ['63450', '63486', 4, 1],
      ['63500', '63500', 3, 1],
      ['63505', '63505', 4, 1],
      ['63512', '63512', 3, 1],
      ['63517', '63526', 4, 1],
      ['63533', '63533', 3, 1],
      ['63538', '63639', 4, 1],
      ['63654', '63674', 3, 1],
      ['63679', '63679', 2, 1],
      ['63683', '63743', 3, 1],
      ['63755', '63939', 4, 1],
      ['64283', '64380', 3, 1],
      ['64385', '64385', 2, 1],
      ['64390', '64390', 3, 1],
      ['64395', '64395', 2, 1],
      ['64397', '64405', 3, 1],
      ['64407', '64407', 2, 1],
      ['64409', '64689', 3, 1],
      ['64711', '64759', 2, 1],
      ['64807', '65232', 3, 1],
      ['65239', '65239', 4, 1],
      ['65307', '65428', 3, 1],
      ['65439', '65439', 4, 1],
      ['65451', '65629', 3, 1],
      ['65719', '65843', 4, 1],
      ['65929', '65936', 3, 1],
      ['66111', '66359', 3, 2],
      ['66386', '66459', 2, 2],
      ['66482', '66589', 3, 2],
      ['66606', '66709', 2, 2],
      ['66740', '66839', 3, 2],
      ['66849', '66892', 2, 2],
      ['66894', '66894', 3, 2],
      ['66901', '66916', 2, 2],
      ['66917', '66999', 3, 2],
      ['67059', '67071', 3, 1],
      ['67098', '67098', 3, 2],
      ['67105', '67141', 2, 2],
      ['67146', '67161', 3, 2],
      ['67165', '67166', 2, 2],
      ['67167', '67169', 3, 2],
      ['67227', '67227', 2, 1],
      ['67229', '67229', 3, 2],
      ['67240', '67245', 2, 2],
      ['67246', '67256', 3, 2],
      ['67258', '67259', 2, 2],
      ['67269', '67283', 3, 2],
      ['67292', '67308', 3, 1],
      ['67310', '67319', 3, 2],
      ['67346', '67435', 2, 2],
      ['67454', '67454', 3, 2],
      ['67459', '67459', 2, 2],
      ['67466', '67475', 3, 2],
      ['67480', '67489', 2, 2],
      ['67547', '67599', 3, 1],
      ['67655', '67707', 2, 2],
      ['67714', '67718', 3, 2],
      ['67722', '67729', 3, 1],
      ['67731', '67759', 2, 2],
      ['67806', '67823', 3, 1],
      ['67824', '68309', 4, 1],
      ['68519', '68519', 3, 1],
      ['68526', '68549', 4, 1],
      ['68623', '68649', 3, 1],
      ['68723', '68723', 4, 1],
      ['68753', '68753', 4, 2],
      ['68766', '68789', 4, 1],
      ['68794', '68794', 4, 2],
      ['68799', '69234', 4, 1],
      ['69239', '69239', 3, 1],
      ['69242', '69259', 4, 1],
      ['69412', '69412', 3, 1],
      ['69427', '69429', 2, 1],
      ['69434', '69436', 3, 1],
      ['69437', '69439', 2, 1],
      ['69469', '70199', 3, 1],
      ['70327', '72160', 2, 1],
      ['72172', '72175', 1, 1],
      ['72178', '72186', 2, 1],
      ['72189', '72189', 1, 1],
      ['72202', '73779', 2, 1],
      ['74072', '74081', 3, 1],
      ['74172', '74399', 2, 1],
      ['74405', '74405', 1, 1],
      ['74417', '74417', 2, 1],
      ['74420', '74599', 1, 1],
      ['74613', '74869', 2, 1],
      ['74889', '74889', 3, 1],
      ['74906', '74906', 2, 1],
      ['74909', '74909', 3, 1],
      ['74912', '74912', 2, 1],
      ['74915', '74927', 3, 1],
      ['74928', '74930', 2, 1],
      ['74931', '74934', 3, 1],
      ['74936', '74936', 2, 1],
      ['74937', '74939', 3, 1],
      ['75015', '75015', 4, 2],
      ['75031', '75031', 2, 1],
      ['75038', '75045', 4, 2],
      ['75050', '75050', 2, 1],
      ['75053', '75059', 4, 2],
      ['75172', '75449', 2, 1],
      ['76131', '76327', 4, 2],
      ['76332', '76332', 2, 1],
      ['76337', '76359', 4, 2],
      ['76437', '76599', 3, 2],
      ['76646', '76709', 4, 2],
      ['76726', '76779', 2, 2],
      ['76829', '76829', 3, 2],
      ['76831', '76835', 2, 2],
      ['76846', '76848', 3, 2],
      ['76855', '76889', 2, 2],
      ['76891', '76891', 3, 2],
      ['77652', '77756', 3, 1],
      ['77761', '77761', 1, 1],
      ['77767', '77770', 3, 1],
      ['77773', '77773', 1, 1],
      ['77776', '77776', 2, 1],
      ['77781', '77799', 3, 1],
      ['77815', '77839', 3, 2],
      ['77855', '77978', 3, 1],
      ['78048', '78126', 2, 2],
      ['78132', '78132', 3, 1],
      ['78136', '78141', 2, 2],
      ['78144', '78144', 1, 1],
      ['78147', '78183', 2, 2],
      ['78187', '78194', 2, 1],
      ['78199', '78199', 2, 2],
      ['78224', '78607', 2, 1],
      ['78609', '78609', 2, 2],
      ['78628', '78628', 1, 1],
      ['78647', '78647', 2, 1],
      ['78652', '78664', 1, 1],
      ['78665', '78665', 2, 1],
      ['78667', '78739', 1, 1],
      ['79098', '79183', 2, 1],
      ['79189', '79206', 3, 1],
      ['79211', '79215', 2, 1],
      ['79219', '79258', 3, 1],
      ['79261', '79263', 2, 1],
      ['79268', '79274', 3, 1],
      ['79276', '79279', 2, 1],
      ['79280', '79295', 3, 1],
      ['79297', '79297', 2, 1],
      ['79299', '79299', 3, 1],
      ['79312', '79353', 2, 1],
      ['79356', '79356', 3, 1],
      ['79359', '79369', 2, 1],
      ['79379', '79650', 3, 1],
      ['79664', '79664', 2, 1],
      ['79669', '79677', 3, 1],
      ['79682', '79682', 2, 1],
      ['79685', '79695', 3, 1],
      ['79713', '79737', 2, 1],
      ['79739', '79739', 3, 1],
      ['79761', '79809', 2, 1],
      ['79822', '79822', 3, 1],
      ['79837', '79837', 2, 1],
      ['79843', '79843', 3, 1],
      ['79848', '79848', 2, 1],
      ['79853', '79859', 3, 1],
      ['79862', '79865', 2, 1],
      ['79868', '79871', 3, 1],
      ['79872', '79872', 2, 1],
      ['79874', '79874', 3, 1],
      ['79875', '79875', 2, 1],
      ['79877', '79877', 3, 1],
      ['79879', '79879', 2, 1],
      ['80331', '80539', 3, 2],
      ['80634', '80689', 2, 2],
      ['80796', '80809', 3, 2],
      ['80933', '82266', 2, 2],
      ['82269', '82269', 1, 2],
      ['82272', '82278', 2, 2],
      ['82279', '82279', 1, 2],
      ['82281', '82296', 2, 2],
      ['82297', '82297', 1, 2],
      ['82299', '82346', 2, 2],
      ['82347', '82347', 1, 1],
      ['82349', '82349', 2, 2],
      ['82362', '82409', 1, 1],
      ['82418', '82435', 2, 2],
      ['82436', '82436', 1, 1],
      ['82438', '84036', 2, 2],
      ['84048', '84048', 2, 1],
      ['84051', '84061', 2, 2],
      ['84066', '84066', 2, 1],
      ['84069', '84069', 3, 1],
      ['84072', '84079', 2, 2],
      ['84082', '84085', 2, 1],
      ['84088', '84088', 2, 2],
      ['84089', '84091', 2, 1],
      ['84092', '84092', 2, 2],
      ['84094', '84094', 2, 1],
      ['84095', '84095', 2, 2],
      ['84097', '84097', 2, 1],
      ['84098', '84104', 2, 2],
      ['84106', '84106', 2, 1],
      ['84107', '84579', 2, 2],
      ['85049', '85057', 2, 1],
      ['85072', '85072', 3, 1],
      ['85077', '85077', 1, 1],
      ['85080', '85080', 3, 1],
      ['85084', '85088', 1, 1],
      ['85092', '85104', 3, 1],
      ['85107', '85107', 1, 1],
      ['85110', '85117', 3, 1],
      ['85119', '85119', 1, 1],
      ['85120', '85122', 3, 1],
      ['85123', '85123', 1, 1],
      ['85125', '85125', 3, 1],
      ['85126', '85126', 1, 1],
      ['85128', '85139', 3, 1],
      ['85221', '85259', 1, 2],
      ['85276', '85305', 1, 1],
      ['85307', '85307', 2, 2],
      ['85309', '85309', 1, 1],
      ['85354', '85748', 2, 2],
      ['85757', '85757', 1, 2],
      ['85764', '85777', 2, 2],
      ['85778', '85778', 1, 2],
      ['86150', '86199', 2, 2],
      ['86316', '86368', 1, 2],
      ['86381', '86381', 2, 2],
      ['86391', '86465', 1, 2],
      ['86470', '86476', 2, 2],
      ['86477', '86477', 1, 2],
      ['86479', '86480', 2, 2],
      ['86482', '86482', 1, 2],
      ['86483', '86483', 2, 2],
      ['86485', '86486', 1, 2],
      ['86488', '86491', 2, 2],
      ['86492', '86497', 1, 2],
      ['86498', '86498', 1, 1],
      ['86500', '86500', 1, 2],
      ['86502', '86502', 2, 1],
      ['86504', '86504', 1, 2],
      ['86505', '86505', 2, 2],
      ['86507', '86511', 1, 2],
      ['86513', '86513', 2, 2],
      ['86514', '86517', 1, 2],
      ['86519', '86519', 2, 2],
      ['86529', '86529', 1, 1],
      ['86551', '86556', 1, 2],
      ['86558', '86558', 1, 1],
      ['86559', '86559', 1, 2],
      ['86561', '86565', 1, 1],
      ['86567', '86570', 1, 2],
      ['86571', '86571', 1, 1],
      ['86573', '86577', 1, 2],
      ['86579', '86579', 1, 1],
      ['86609', '86609', 2, 1],
      ['86633', '86633', 1, 1],
      ['86637', '86641', 2, 1],
      ['86643', '86643', 1, 1],
      ['86647', '86663', 2, 1],
      ['86666', '86669', 1, 1],
      ['86672', '86672', 1, 2],
      ['86673', '86673', 1, 1],
      ['86674', '86674', 1, 2],
      ['86675', '86675', 2, 1],
      ['86676', '86676', 1, 1],
      ['86678', '86679', 1, 2],
      ['86681', '86694', 2, 1],
      ['86695', '86695', 1, 2],
      ['86697', '86697', 1, 1],
      ['86698', '86700', 2, 1],
      ['86701', '86701', 1, 1],
      ['86703', '86704', 2, 1],
      ['86706', '86706', 1, 1],
      ['86707', '86707', 1, 2],
      ['86709', '86759', 2, 1],
      ['86807', '86825', 1, 1],
      ['86830', '86830', 1, 2],
      ['86833', '86833', 1, 1],
      ['86836', '86836', 1, 2],
      ['86842', '86842', 1, 1],
      ['86845', '86853', 1, 2],
      ['86854', '86854', 1, 1],
      ['86856', '86859', 1, 2],
      ['86860', '86862', 1, 1],
      ['86863', '86863', 1, 2],
      ['86865', '86865', 1, 1],
      ['86866', '86868', 1, 2],
      ['86869', '86871', 1, 1],
      ['86872', '86872', 1, 2],
      ['86874', '86875', 1, 1],
      ['86877', '86877', 1, 2],
      ['86879', '86879', 1, 1],
      ['86899', '86949', 1, 2],
      ['86956', '86972', 1, 1],
      ['86974', '86974', 1, 2],
      ['86975', '86980', 1, 1],
      ['86981', '86981', 1, 2],
      ['86983', '87679', 1, 1],
      ['87700', '87700', 2, 1],
      ['87719', '87789', 1, 1],
      ['88045', '88099', 2, 1],
      ['88131', '88145', 1, 1],
      ['88147', '88147', 2, 1],
      ['88149', '88179', 1, 1],
      ['88212', '89299', 2, 1],
      ['89312', '89343', 2, 2],
      ['89344', '89344', 2, 1],
      ['89346', '89352', 2, 2],
      ['89353', '89353', 2, 1],
      ['89355', '89368', 2, 2],
      ['89407', '89619', 2, 1],
      ['90402', '90513', 3, 1],
      ['90518', '90518', 2, 1],
      ['90522', '90522', 3, 1],
      ['90530', '90537', 2, 1],
      ['90542', '90547', 3, 1],
      ['90552', '90552', 2, 1],
      ['90556', '90556', 3, 1],
      ['90559', '90559', 2, 1],
      ['90562', '90562', 3, 1],
      ['90571', '90571', 2, 1],
      ['90574', '90579', 3, 1],
      ['90584', '90584', 2, 1],
      ['90587', '90587', 3, 1],
      ['90592', '90610', 2, 1],
      ['90613', '90614', 3, 1],
      ['90616', '90616', 2, 1],
      ['90617', '90617', 3, 1],
      ['90619', '90619', 2, 1],
      ['90762', '90768', 3, 1],
      ['91052', '91058', 2, 1],
      ['91074', '91126', 3, 1],
      ['91154', '91249', 2, 1],
      ['91257', '91257', 3, 1],
      ['91275', '91275', 2, 1],
      ['91278', '91278', 3, 1],
      ['91281', '91281', 2, 1],
      ['91282', '91282', 3, 1],
      ['91284', '91284', 2, 1],
      ['91286', '91330', 3, 1],
      ['91332', '91332', 2, 1],
      ['91334', '91369', 3, 1],
      ['91413', '91448', 2, 1],
      ['91452', '91452', 3, 1],
      ['91456', '91474', 2, 1],
      ['91475', '91475', 3, 1],
      ['91477', '91486', 2, 1],
      ['91487', '91487', 3, 1],
      ['91489', '91793', 2, 1],
      ['91795', '91795', 3, 1],
      ['91796', '91802', 2, 1],
      ['91804', '91804', 3, 1],
      ['91805', '91807', 2, 1],
      ['91809', '91809', 3, 1],
      ['92224', '92334', 2, 1],
      ['92339', '92339', 3, 1],
      ['92342', '92729', 2, 1],
      ['93047', '93073', 3, 1],
      ['93077', '93077', 2, 1],
      ['93080', '93128', 3, 1],
      ['93133', '93133', 2, 1],
      ['93138', '93138', 3, 1],
      ['93142', '93149', 2, 1],
      ['93152', '93155', 3, 1],
      ['93158', '93158', 2, 1],
      ['93161', '93164', 3, 1],
      ['93167', '93167', 2, 1],
      ['93170', '93183', 3, 1],
      ['93185', '93185', 2, 1],
      ['93186', '93188', 3, 1],
      ['93189', '93194', 2, 1],
      ['93195', '93197', 3, 1],
      ['93199', '93333', 2, 1],
      ['93336', '93336', 3, 1],
      ['93339', '93348', 2, 1],
      ['93349', '93349', 3, 1],
      ['93351', '93470', 2, 1],
      ['93471', '93471', 3, 1],
      ['93473', '93499', 2, 1],
      ['94032', '94136', 3, 1],
      ['94137', '94137', 2, 2],
      ['94139', '94139', 3, 1],
      ['94140', '94140', 2, 2],
      ['94142', '94164', 3, 1],
      ['94166', '94166', 2, 2],
      ['94167', '94269', 3, 1],
      ['94315', '94315', 4, 1],
      ['94327', '94379', 2, 1],
      ['94405', '94439', 2, 2],
      ['94447', '94518', 3, 1],
      ['94522', '94522', 2, 2],
      ['94526', '94551', 3, 1],
      ['94553', '94553', 2, 1],
      ['94554', '94557', 3, 1],
      ['94559', '94559', 2, 1],
      ['94560', '94579', 3, 1],
      ['95028', '95239', 2, 1],
      ['95326', '95369', 3, 1],
      ['95444', '95448', 2, 1],
      ['95460', '95473', 3, 1],
      ['95478', '95478', 2, 1],
      ['95482', '95503', 3, 1],
      ['95505', '95508', 2, 1],
      ['95509', '95512', 3, 1],
      ['95514', '95514', 2, 1],
      ['95515', '95517', 3, 1],
      ['95519', '95685', 2, 1],
      ['95686', '95686', 3, 1],
      ['95688', '95692', 2, 1],
      ['95694', '95694', 3, 1],
      ['95695', '96103', 2, 1],
      ['96106', '96106', 3, 1],
      ['96110', '96123', 2, 1],
      ['96126', '96126', 3, 1],
      ['96129', '96138', 2, 1],
      ['96142', '96142', 3, 1],
      ['96145', '96149', 2, 1],
      ['96151', '96151', 3, 1],
      ['96152', '96158', 2, 1],
      ['96160', '96160', 3, 1],
      ['96161', '96164', 2, 1],
      ['96166', '96166', 3, 1],
      ['96167', '96170', 2, 1],
      ['96172', '96172', 3, 1],
      ['96173', '96175', 2, 1],
      ['96176', '96176', 3, 1],
      ['96178', '96179', 2, 1],
      ['96181', '96181', 3, 1],
      ['96182', '96182', 2, 1],
      ['96184', '96184', 3, 1],
      ['96185', '96187', 2, 1],
      ['96188', '96190', 3, 1],
      ['96191', '96191', 2, 1],
      ['96193', '96193', 3, 1],
      ['96194', '96196', 2, 1],
      ['96197', '96197', 3, 1],
      ['96199', '96199', 2, 1],
      ['96215', '96231', 3, 1],
      ['96237', '96242', 2, 1],
      ['96247', '96250', 3, 1],
      ['96253', '96253', 2, 1],
      ['96257', '96264', 3, 1],
      ['96268', '96271', 2, 1],
      ['96272', '96272', 3, 1],
      ['96274', '96274', 2, 1],
      ['96275', '96275', 3, 1],
      ['96277', '96369', 2, 1],
      ['96450', '96450', 3, 1],
      ['96465', '96489', 2, 1],
      ['96515', '96529', 1, 1],
      ['97070', '97084', 4, 1],
      ['97199', '97209', 3, 1],
      ['97215', '97215', 2, 1],
      ['97218', '97256', 3, 1],
      ['97258', '97258', 2, 1],
      ['97259', '97859', 3, 1],
      ['97877', '97877', 2, 1],
      ['97892', '97892', 3, 1],
      ['97896', '97900', 2, 1],
      ['97901', '97906', 4, 1],
      ['97907', '97907', 3, 1],
      ['97909', '97909', 4, 1],
      ['97922', '97999', 2, 1],
      ['98527', '98749', 1, 1],
      ['99084', '99099', 2, 1],
      ['99100', '99100', 1, 1],
      ['99102', '99102', 2, 1],
      ['99189', '99195', 1, 1],
      ['99198', '99198', 2, 1],
      ['99310', '99998', 1, 1]
    ],

    getTarifzone(plz: any) {
      for (const i in this.tarifzonen) {
        if (this.tarifzonen.hasOwnProperty(i)) {
          const item = this.tarifzonen[i];
          if (plz >= item[0] && plz <= item[1]) { return item[2] + '/' + item[3]; }
        } else {
          return null;
        }
      }
    }
*/
  };

  constructor() { }
}
