/* eslint-disable max-len */
import { Component } from '@angular/core';
import { HaftpflichtService } from 'src/app/services/calculators/haftpflicht.service';
import { HausratService } from 'src/app/services/calculators/hausrat.service';
import { WohngebaeudeService } from 'src/app/services/calculators/wohngebaeude.service';
import { UnfallService } from 'src/app/services/calculators/unfall.service';
import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-zahlweise',
  templateUrl: './zahlweise.component.html',
  styleUrls: ['./zahlweise.component.scss'],
})
export class ZahlweiseComponent {
  totalCalculation = {
    gesamtZahlGemZW: 0,
    gesamtNetto: 0,
    gesamtVersicherungssteuer: 0,
    gesamtZahl: 0,
  };

  constructor(public cs: ContextService, private haftCalc: HaftpflichtService, private hausCalc: HausratService, private wohnCalc: WohngebaeudeService, private unfallCalc: UnfallService) {
    this.sumCalculation();
  }

  updateZahlweise() {
    this.sumCalculation();
  }

  addCalculationToSum(calculation) {
    this.totalCalculation.gesamtZahlGemZW += calculation.gesamtZahlGemZW;
    this.totalCalculation.gesamtNetto += calculation.gesamtNetto;
    this.totalCalculation.gesamtVersicherungssteuer += calculation.gesamtVersicherungssteuer;
    this.totalCalculation.gesamtZahl += calculation.gesamtZahl;
  }

  sumCalculation() {
    this.totalCalculation = {
      gesamtZahlGemZW: 0,
      gesamtNetto: 0,
      gesamtVersicherungssteuer: 0,
      gesamtZahl: 0,
    };

    if (this.cs.process.products.haftpflicht.is_selected) {
      this.haftCalc.getCalculation();
      this.addCalculationToSum(this.cs.process.products.haftpflicht.calculation);
    }
    if (this.cs.process.products.hausrat.is_selected) {
      this.hausCalc.getCalculation();
      this.addCalculationToSum(this.cs.process.products.hausrat.calculation);
    }
    if (this.cs.process.products.wohngebaeude.is_selected) {
      this.wohnCalc.getCalculation();
      this.addCalculationToSum(this.cs.process.products.wohngebaeude.calculation);
    }
     if (this.cs.process.products.unfall.is_selected) {
      this.unfallCalc.getCalculation();
      this.addCalculationToSum(this.cs.process.products.unfall.calculation);
     }

     this.cs.process.calculation = this.totalCalculation;
  }

}
