/* eslint-disable max-len */
import { Component } from '@angular/core';
import { addYears } from 'date-fns';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-vorversicherungen-wohngebaude',
  templateUrl: './vorversicherungen-wohngebaude.component.html',
  styleUrls: ['./vorversicherungen-wohngebaude.component.scss'],
})
export class VorversicherungenWohngebaudeComponent {

  versichererValid = '';
  versicherungsnummerValid = '';
  anzahlVorschaedenValid = '';
  beschreibungVorschaedenValid = '';
  maxKuendigung = undefined;

  constructor(public cs: ContextService, public vs: ValidationService) {
    this.maxKuendigung = addYears(new Date(), 1).toISOString();
  }

  validateFormField(fieldName: string) {
    if (fieldName === 'versicherer') {
      this.versichererValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.wohngebaeude.versicherer);
    }
    if (fieldName === 'versicherungsnummer') {
      this.versicherungsnummerValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.wohngebaeude.versicherungsnummer);
    }
    if (fieldName === 'anzahlVorschaeden') {
      this.anzahlVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.wohngebaeude.anzahl_vorschaeden);
    }
    if (fieldName === 'beschreibungVorschaeden') {
      this.beschreibungVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.wohngebaeude.beschreibung_vorschaeden);
    }
  }

}
