/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ContextService } from '../context.service';
import { WohngebaeudeDataService } from '../tarif-data/Wohngebaeude-data.service';
import { WohngebaeudeData2023Service } from '../tarif-data/wohngebaeude-data-2023.service';
import { parseISO, getYear } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class WohngebaeudeService {
  wgservice;
  constructor(public cs: ContextService, public wgs: WohngebaeudeDataService, public wgs2023: WohngebaeudeData2023Service) { }

  getCalculation() {
    const result = Object();
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.wohngebaeude.input.variant;
    if (getYear(parseISO(this.cs.process.products.wohngebaeude.input.begin)) > 2022) {
      this.wgservice = this.wgs2023;
    } else {
      this.wgservice = this.wgs;
    }
    const tarifData = this.wgservice.wohngebaeudeData;

    const tarifZone = this.cs.process.risk_address.zones.ZoneLeitungsWasser + '/' + this.cs.process.risk_address.zones.ZoneSturm;

    const alter: number = getYear(new Date()) - this.cs.process.products.wohngebaeude.input.ursprungsbaujahr;
    let alterGroup = '';
    if (alter < 8) { alterGroup = 'neu'; }
    if (alter > 7 && alter < 17) { alterGroup = 'ab8'; }
    if (alter > 16) { alterGroup = 'ab17'; }

    const qm = this.cs.process.products.wohngebaeude.input.wohnflaeche;
    let zonen = {};
    if (tarifKey === 'premium') {
      zonen = tarifData.tarife.premium.zonen;
    }
    if (tarifKey === 'premium_plus') {
      zonen = tarifData.tarife.premium_plus.zonen_ohne_ug_mg;
      if (this.cs.process.products.wohngebaeude.input.modules.include_marktgarantie) {
        zonen = tarifData.tarife.premium_plus.zonen;
      }
    }

    const jnp_qm = zonen[alterGroup][tarifZone];
    let zuschlaege = 0;
    let anzZahlungen_lbl = 'jährlich';
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        anzZahlungen_lbl = 'jährlich';
        break;
      case 2:
        zuschlaege += 3;
        anzZahlungen_lbl = '1/2 jährlich';
        break;
      case 4:
        zuschlaege += 5;
        anzZahlungen_lbl = '1/4 jährlich';
        break;
      case 12:
        zuschlaege += 5;
        anzZahlungen_lbl = 'monatlich';
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.steuersatz = tarifData.taxRate;
    result.steuersatzElementar = tarifData.taxRateElementar;

    result.wohngebaeudeNetto = Math.round(qm * jnp_qm) * (1 + zuschlaege / 100);
    result.wohngebaeudeNetto = Math.round(result.wohngebaeudeNetto / zahlweise) * zahlweise;
    result.wohngebaeudeVersicherungssteuer = Math.round(result.wohngebaeudeNetto * result.steuersatz);
    result.wohngebaeudeVersicherungssteuer = Math.round(result.wohngebaeudeVersicherungssteuer / zahlweise) * zahlweise;
    result.wohngebaeudeZahl = result.wohngebaeudeNetto + result.wohngebaeudeVersicherungssteuer;
    result.wohngebaeudeZahlGemZW = result.wohngebaeudeZahl / zahlweise;

    const benefitCalcResult = this.getBenefitCalculation();
    result.benefitNetto = benefitCalcResult.beitragGesamtNetto;
    result.benefitVersicherungssteuer = benefitCalcResult.beitragGesamtVersicherungssteuer;
    result.benefitZahl = benefitCalcResult.beitragGesamtZahl;
    result.benefitZahlGemZW = benefitCalcResult.beitragGesamtZahlGemZW;

    if (this.cs.process.products.wohngebaeude.input.modules.include_glas) {
      const glasCalcResult = this.getGlasCalculation();
      result.glasNetto = glasCalcResult.beitragGesamtNetto;
      result.glasVersicherungssteuer = glasCalcResult.beitragGesamtVersicherungssteuer;
      result.glasZahl = glasCalcResult.beitragGesamtZahl;
      result.glasZahlGemZW = glasCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.glasNetto = 0;
      result.glasVersicherungssteuer = 0;
      result.glasZahl = 0;
      result.glasZahlGemZW = 0;
    }

    if (this.cs.process.products.wohngebaeude.input.modules.include_smarthome) {
      const smartHomeCalcResult = this.getSmartHomeCalculation();
      result.smartHomeNetto = smartHomeCalcResult.beitragGesamtNetto;
      result.smartHomeVersicherungssteuer = smartHomeCalcResult.beitragGesamtVersicherungssteuer;
      result.smartHomeZahl = smartHomeCalcResult.beitragGesamtZahl;
      result.smartHomeZahlGemZW = smartHomeCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.smartHomeNetto = 0;
      result.smartHomeVersicherungssteuer = 0;
      result.smartHomeZahl = 0;
      result.smartHomeZahlGemZW = 0;
    }

    if (this.cs.process.products.wohngebaeude.input.modules.include_elementar) {
      const elementarCalcResult = this.getElementarCalculation();
      result.elementarNetto = elementarCalcResult.beitragGesamtNetto;
      result.elementarVersicherungssteuer = elementarCalcResult.beitragGesamtVersicherungssteuer;
      result.elementarZahl = elementarCalcResult.beitragGesamtZahl;
      result.elementarZahlGemZW = elementarCalcResult.beitragGesamtZahlGemZW;
    } else {
      result.elementarNetto = 0;
      result.elementarVersicherungssteuer = 0;
      result.elementarZahl = 0;
      result.elementarZahlGemZW = 0;
    }

    result.gesamtNetto = result.wohngebaeudeNetto + result.benefitNetto + result.elementarNetto + result.glasNetto + result.smartHomeNetto;
    result.gesamtVersicherungssteuer = result.wohngebaeudeVersicherungssteuer + result.benefitVersicherungssteuer + result.elementarVersicherungssteuer + result.glasVersicherungssteuer + result.smartHomeVersicherungssteuer;
    result.gesamtZahl = result.wohngebaeudeZahl + result.benefitZahl + result.elementarZahl + result.glasZahl + result.smartHomeZahl;
    result.gesamtZahlGemZW = result.wohngebaeudeZahlGemZW + result.benefitZahlGemZW + result.elementarZahlGemZW + result.glasZahlGemZW + result.smartHomeZahlGemZW;

    result.glasSteuerProz = this.wgservice.glasData.taxRate;
    result.wohngebaeudeSteuerProz = this.wgservice.wohngebaeudeData.taxRate;
    result.wohngebaeudeElementarSteuerProz = this.wgservice.wohngebaeudeData.taxRateElementar;

    result.hauptNetto = result.wohngebaeudeNetto + result.benefitNetto + result.elementarNetto + result.smartHomeNetto;
    result.hauptVersicherungssteuer = result.wohngebaeudeVersicherungssteuer + result.benefitVersicherungssteuer + result.elementarVersicherungssteuer + result.smartHomeVersicherungssteuer;
    result.hauptZahl = result.wohngebaeudeZahl + result.benefitZahl + result.elementarZahl + result.smartHomeZahl;
    result.hauptZahlGemZW = result.wohngebaeudeZahlGemZW + result.benefitZahlGemZW + result.elementarZahlGemZW + result.smartHomeZahlGemZW;
    result.hauptSteuerProz = this.wgservice.wohngebaeudeData.taxRate;
    this.cs.process.products.wohngebaeude.calculation = { ...result };
  }

  getBenefitCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.wohngebaeude.input.variant;
    const tarifData = this.wgservice.wohngebaeudeData;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = tarifData.tarife[tarifKey].benefit;
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getGlasCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.wohngebaeude.input.variant;
    const tarifData = this.wgservice.glasData;
    const perqm = tarifData.tarife[tarifKey].perqm;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    const qm = this.cs.process.products.wohngebaeude.input.wohnflaeche;
    result.beitragGesamtNetto = Math.round(qm * perqm);
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getSmartHomeCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.wohngebaeude.input.variant;
    const tarifData = this.wgservice.wohngebaeudeData;
    const result = Object();
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = tarifData.tarife[tarifKey].smart_home;
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

  getElementarCalculation() {
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.wohngebaeude.input.variant;
    const tarifData = this.wgservice.wohngebaeudeData;
    const result = Object();
    const zuersZonen = this.wgservice.wohngebaeudeData.tarife[tarifKey].zuersZonen;
    const qm = this.cs.process.products.wohngebaeude.input.wohnflaeche;
    result.steuersatz = tarifData.taxRate;
    result.beitragGesamtNetto = Math.round(qm * zuersZonen[parseInt(this.cs.process.risk_address.zones.Zone, 10)]);
    let zuschlaege = 0;
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        break;
      case 2:
        zuschlaege += 3;
        break;
      case 4:
        zuschlaege += 5;
        break;
      case 12:
        zuschlaege += 5;
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.beitragGesamtNetto = result.beitragGesamtNetto * (1 + zuschlaege / 100);
    result.beitragGesamtNetto = Math.round(result.beitragGesamtNetto / zahlweise) * zahlweise;
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtNetto * result.steuersatz);
    result.beitragGesamtVersicherungssteuer = Math.round(result.beitragGesamtVersicherungssteuer / zahlweise) * zahlweise;
    result.beitragGesamtZahl = result.beitragGesamtNetto + result.beitragGesamtVersicherungssteuer;
    result.beitragGesamtZahlGemZW = result.beitragGesamtZahl / zahlweise;
    return result;
  }

}

