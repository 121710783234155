/* eslint-disable max-len */
import { Component } from '@angular/core';
import { addYears } from 'date-fns';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-vorversicherungen-haftpflicht',
  templateUrl: './vorversicherungen-haftpflicht.component.html',
  styleUrls: ['./vorversicherungen-haftpflicht.component.scss'],
})
export class VorversicherungenHaftpflichtComponent {
  kuendigungDurchValid = '';
  versichererValid = '';
  versicherungsnummerValid = '';
  anzahlVorschaedenValid = '';
  beschreibungVorschaedenValid = '';
  maxKuendigung = undefined;

  constructor(public cs: ContextService, public vs: ValidationService) {
    this.maxKuendigung = addYears(new Date(), 1).toISOString();
  }

  validateFormField(fieldName: string) {
    if (fieldName === 'kuendigung_durch') {
      this.kuendigungDurchValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.kuendigung_durch);
    }
    if (fieldName === 'versicherer') {
      this.versichererValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.versicherer);
    }
    if (fieldName === 'versicherungsnummer') {
      this.versicherungsnummerValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.versicherungsnummer);
    }
    if (fieldName === 'anzahlVorschaeden') {
      this.anzahlVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.anzahl_vorschaeden);
    }
    if (fieldName === 'beschreibungVorschaeden') {
      this.beschreibungVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.beschreibung_vorschaeden);
    }
  }

}
