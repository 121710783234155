import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root'
})
export class ZuersService {

  constructor(private http: HttpClient, private cs: ContextService) { }

  async getPlzList() {
    return this.http.post(`${environment.apiUrl}/api/zuers/list_plz`, {}).toPromise();
  }

  async getAddress(zip: any, street: string) {
    return this.http.post(`${environment.apiUrl}/api/zuers/list`, { zip, street }).toPromise();
  }

  async getZuersZones(zip: any, street: any, housenumber: any, city: any, district: any) {
    return this.http.post(`${environment.apiUrl}/api/zuers/get`, { zip, street, housenumber, city, district }).toPromise();
  }

}
