/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { ContextService } from 'src/app/services/context.service';
import { UserService } from 'src/app/services/user.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ZuersService } from 'src/app/services/zuers.service';
import { AddressModalComponent } from '../address-modal/address-modal.component';

@Component({
  selector: 'app-sepa-fields',
  templateUrl: './sepa-fields.component.html',
  styleUrls: ['./sepa-fields.component.scss'],
})
export class SepaFieldsComponent {

  @ViewChild('zipInput') zipInput: IonInput;
  @ViewChild('streetInput') streetInput: IonInput;

  showRestOfFields = false;
  streets = undefined;
  streetFromService = '';
  showRestOfSepaFields = false;
  firstnameSepaValid = '';
  lastnameSepaValid = '';
  zipSepaValid = '';
  streetSepaValid = '';
  housenumberSepaValid = '';
  ibanValid = '';

  constructor(public cs: ContextService, public userService: UserService, public zuers: ZuersService, public modalCtrl: ModalController, public vs: ValidationService) { }

  validateFormField(fieldName: string) {
    if (fieldName === 'firstname') {
      this.firstnameSepaValid = this.vs.validateField(this.cs.process.antrag.sepa.abweichender_kontoinhaber.firstname);
    }
    if (fieldName === 'lastname') {
      this.lastnameSepaValid = this.vs.validateField(this.cs.process.antrag.sepa.abweichender_kontoinhaber.lastname);
    }
    if (fieldName === 'zip') {
      this.zipSepaValid = this.vs.validatePlz(this.cs.process.antrag.sepa.abweichender_kontoinhaber.zip);
    }
    if (fieldName === 'street') {
      this.streetSepaValid = this.vs.validateField(this.cs.process.antrag.sepa.abweichender_kontoinhaber.street);
    }
    if (fieldName === 'housenumber') {
      this.housenumberSepaValid = this.vs.validateField(this.cs.process.antrag.sepa.abweichender_kontoinhaber.housenumber);
    }
    if (fieldName === 'iban') {
      this.ibanValid = this.vs.validateIBan(this.cs.process.antrag.sepa.iban);
    }
  }

  async verifyIBan() {
    if (this.cs.process.antrag.sepa?.iban.length === 27) {
      try {
        const res = await this.userService.verifyIban(this.cs.process.antrag.sepa.iban);
        if (res['errors'].length === 0) {
          //console.log(res);
          this.showRestOfFields = true;
          this.cs.process.antrag.sepa.bic = res['iban_parts'].bic;
          this.cs.process.antrag.sepa.kto = res['iban_parts'].kto;
          this.cs.process.antrag.sepa.blz = res['iban_parts'].blz;
          this.cs.process.antrag.sepa.bezeichnung = res['iban_parts'].bezeichnung;
        } else {
          this.showRestOfFields = false;
          this.cs.process.antrag.sepa.bic = '';
          this.cs.process.antrag.sepa.kto = res['iban_parts'].kto;
          this.cs.process.antrag.sepa.blz = res['iban_parts'].blz;
          this.cs.process.antrag.sepa.bezeichnung = '';
        }
      } catch (error) {
        console.log(error);
        this.showRestOfFields = false;
        this.cs.process.antrag.sepa.bic = '';
        this.cs.process.antrag.sepa.bezeichnung = '';
      }
    } else {
      this.showRestOfFields = false;
      this.cs.process.antrag.sepa.bic = '';
      this.cs.process.antrag.sepa.bezeichnung = '';
    }
    this.validateFormField('iban');
  }

  async getAddresses() {
    if (this.streetFromService !== this.cs.process.antrag.sepa.abweichender_kontoinhaber.street) {
    const zipInputEL = await this.zipInput.getInputElement();
    const streetInputEL = await this.streetInput.getInputElement();
    if (String(this.cs.process.antrag.sepa.abweichender_kontoinhaber.zip)?.length === 5 && this.cs.process.antrag.sepa.abweichender_kontoinhaber.street?.length >= 2) {
      const res = await this.zuers.getAddress(this.cs.process.antrag.sepa.abweichender_kontoinhaber.zip, this.cs.process.antrag.sepa.abweichender_kontoinhaber.street);
      this.streets = res['streets'];
      if (this.streets && this.streets.length > 1) {
        this.showRestOfSepaFields = true;
        zipInputEL.blur();
        streetInputEL.blur();
        await this.presentModal();
      } else if (this.streets) {
        this.showRestOfSepaFields = true;
        this.streetFromService = this.streets.strasse;
        this.cs.process.antrag.sepa.abweichender_kontoinhaber.street = this.streets.Strasse;
        this.cs.process.antrag.sepa.abweichender_kontoinhaber.city = this.streets.Ort;
        zipInputEL.blur();
        streetInputEL.blur();
      } else {
        this.showRestOfSepaFields = false;
        this.streets = null;
        this.streetFromService = '';
        this.cs.process.antrag.sepa.abweichender_kontoinhaber.housenumber = '';
      }
    } else {
      this.showRestOfSepaFields = false;
      this.streets = null;
      this.cs.process.antrag.sepa.abweichender_kontoinhaber.housenumber = '';
    }
  }
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AddressModalComponent,
      componentProps: {
        streets: this.streets,
      }
    });

    modal.onDidDismiss()
      .then((data) => {
        const address = data.data['selectedAddress'];
        this.streetFromService = address.Strasse;
        this.cs.process.antrag.sepa.abweichender_kontoinhaber.street = address.Strasse;
        this.cs.process.antrag.sepa.abweichender_kontoinhaber.city = address.Ort;
      });

    return await modal.present();
  }

}
