/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ContextService } from '../context.service';
import { UnfallDataService } from '../tarif-data/unfall-data.service';

@Injectable({
  providedIn: 'root'
})
export class UnfallService {

  public tarifDefintionGesamt: any;

  constructor(public cs: ContextService, public us: UnfallDataService) { }

  myMult(a, b) {
    return Math.round(a * b * 100) / 100;
  }

  getEmpty(toProcess) {
    if (toProcess === null || toProcess === undefined) { toProcess = ''; }
    if (typeof toProcess === 'string' || toProcess instanceof String) {
      return toProcess.trim();
    } else {
      return toProcess;
    }
  }

  getDateAsLong(datum) {
    if (datum != null) {
      datum = new Date(datum);
      let dd = datum.getDate();
      let mm = datum.getMonth() + 1;
      const yyyy = datum.getFullYear();
      if (dd < 10) { dd = '0' + dd; }
      if (mm < 10) { mm = '0' + mm; }
      datum = '';
      datum = datum.concat(yyyy, mm, dd);
    }
    return datum;
  }

  //ToDo: use Vertragsbeginn as base --> not  DATE()
  getAlter(geburtsdatum) {
    return Math.floor((this.getDateAsLong(new Date()) - this.getDateAsLong(new Date(geburtsdatum))) / 10000);
  }

  hasSpecialBeruf(beruf) {
    return (this.us.unfallData.specialBerufe.indexOf(this.us.unfallData.berufe[beruf]) > -1);
  }

  getGeschlecht(salutation) {
    if (salutation === 'Herr') { return '0'; }
    if (salutation === 'Divers') { return '0'; }
    if (salutation === 'Frau') { return '1'; }
    return '';
  }

  getInt(toProcess) {
    if (toProcess == null) { toProcess = '0'; }
    return parseInt(toProcess);
  }

  getGefahrengruppeViaBeruf(beruf, arbeitsverhaeltnis, artTaetigkeit, geschlecht) {
    const kombinationen = this.us.unfallData.kombinationen;
    beruf = parseInt(this.getEmpty(beruf));
    arbeitsverhaeltnis = parseInt(this.getEmpty(arbeitsverhaeltnis));
    artTaetigkeit = parseInt(this.getEmpty(artTaetigkeit));
    geschlecht = parseInt(this.getEmpty(geschlecht));

    for (const item in kombinationen) {
      if ((beruf === kombinationen[item][0])
        && (arbeitsverhaeltnis === kombinationen[item][1])
        && (artTaetigkeit === kombinationen[item][2])
        && ((kombinationen[item][3] === 2) || (geschlecht === kombinationen[item][3]))) //Hack: 2 = Unisex
      { return this.us.unfallData.gefahrengruppen[kombinationen[item][4]]; }
    }
    return false;
  }

  getGefahrengruppe(person) {
    const alter = this.getAlter(person.personal_data.birthdate);
    let gefahrengruppe = null;
    if (alter <= 17) { gefahrengruppe = 'k'; }
    if (alter >= 18 && alter <= 24) { gefahrengruppe = 'j'; }
    if (alter >= 25 && alter <= 54) { //a/b --> verwende Beruf
      //Spezialbehandlung für Berufe ohne Tätigkeitsart und Arbeitsverhältnis
      //ACHTUNG: in der Excel Tabelle wird hier zwischen Geschlechtern unterschieden. Es kommt aber für beide Geschlechter die gleich Gefahrengruppe raus.
      //Um nicht die tarif-unfall.data.js ändern zu müssen --> Vereinfachung in der Form, dass die Geschlechter bei den Special Berufen nicht berücksichtigt werden!
      if (this.hasSpecialBeruf(person.personal_data.beruf)) {
        //per Vorgabe: A
        gefahrengruppe = 'a';
        //Falls in Liste mit Berufen aus denen hervorgeht, dass Person < 18 Jahre: K
        if (this.us.unfallData.specialBerufeGefahrengruppeK.indexOf(this.us.unfallData.berufe[person.personal_data.beruf]) > -1) { gefahrengruppe = 'k'; }
        //Es gibt einen Beruf, der in die Gefahrengruppe V fällt: B
        if (this.us.unfallData.berufe[person.personal_data.beruf] === 'Mithelfende/r Ehegatt(e)/in im Büro oder Verkauf bei selbstständigen Handels- und Handwerksbetrieben')
        { gefahrengruppe = 'b'; }
      } else {
        gefahrengruppe = this.getGefahrengruppeViaBeruf(person.personal_data.beruf, person.personal_data.arbeitsverhaeltnis, person.personal_data.art_der_taetigkeit, this.getGeschlecht(person.personal_data.salutation));
      }

      if ('gvs'.indexOf(gefahrengruppe) !== -1) { //
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'körperlich tätig') { gefahrengruppe = 'b'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'nicht körperlich tätig') { gefahrengruppe = 'a'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'Azubi / Arbeitgeber nicht ö. D.') { gefahrengruppe = 'a'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'mithelfender Familienangehöriger') { gefahrengruppe = 'b'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'gewerblicher Azubi') { gefahrengruppe = 'a'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'Azubi') { gefahrengruppe = 'a'; }
        if (this.us.unfallData.artTaetigkeiten[person.personal_data.art_der_taetigkeit] === 'Anwärter/in') { gefahrengruppe = ''; }
      }
      if (gefahrengruppe === 's') { gefahrengruppe = 'b'; }
    }
    if (alter >= 55 && alter <= 75) { gefahrengruppe = 'se'; }
    if (alter > 75) { gefahrengruppe = 'nv'; } //nicht versicherbar
    return gefahrengruppe;
  }

  getDefTarif(leistung, tarif) {
    const defLeistung = this.tarifDefintionGesamt.leistungen[leistung];
    let defTarifbaustein = null;
    if (tarif != null) {
      for (let i = 0; i < defLeistung.length; i++) {
        defTarifbaustein = defLeistung[i];
        if (defTarifbaustein.name === tarif) { return defTarifbaustein; }
      }
      console.log('Fehler in tarifData: Tarif "' + tarif + '" wurde nicht gefunden!');
      return null;
    } else {
      return defLeistung;
    }
  }

  berechneTarifbaustein(leistung, tarif, wert, gefahrengruppe, result) {
    const defTarifbaustein = this.getDefTarif(leistung, tarif);
    wert = this.getInt(wert);
    const erg = Object();
    erg.beitrag = this.myMult(wert / defTarifbaustein.einheit, defTarifbaustein[gefahrengruppe]);
    erg.bezeichnung = defTarifbaustein.name;
    erg.wert = wert;
    erg.beitragRabatt = this.myMult(erg.beitrag, result.rabatte / 100);
    erg.beitragInklRabatt = erg.beitrag - erg.beitragRabatt;
    erg.beitragZuschlag = this.myMult(erg.beitragInklRabatt, result.zuschlaege / 100);
    erg.beitragNetto = erg.beitragInklRabatt + erg.beitragZuschlag;
    erg.beitragVersicherungssteuer = this.myMult(erg.beitragNetto, this.us.unfallData.taxRate);
    erg.beitragZahl = erg.beitragNetto + erg.beitragVersicherungssteuer;

    return erg;
  }

  calcMitAnzZahlungen(wert, anzZahlungen) {
    return this.myMult(Math.round(wert / anzZahlungen), anzZahlungen);
  }

  getCalculation() {
    const result = Object();
    const zahlweise = this.cs.process.products.payment_frequency;
    const tarifKey = this.cs.process.products.unfall.input.variant;
    const tarifData = this.us.unfallData;

    result.berechnungsergebnisse = new Array();
    this.tarifDefintionGesamt = tarifData.tarife[tarifKey];

    result.unfallNetto = 0.0;
    result.unfallVersicherungssteuer = 0;
    result.unfallZahl = 0;

    result.rabatte = 10; // 10% auf jeden Fall wegen > 3 Jahren Vertragsdauer

    let zuschlaege = 0;
    let anzZahlungen_lbl = 'jährlich';
    switch (zahlweise) {
      case 1:
        zuschlaege += 0;
        anzZahlungen_lbl = 'jährlich';
        break;
      case 2:
        zuschlaege += 3;
        anzZahlungen_lbl = '1/2 jährlich';
        break;
      case 4:
        zuschlaege += 5;
        anzZahlungen_lbl = '1/4 jährlich';
        break;
      case 12:
        zuschlaege += 5;
        anzZahlungen_lbl = 'monatlich';
        break;
    }

    result.anzZahlungen = zahlweise;
    result.zuschlaege = zuschlaege;
    result.steuersatz = tarifData.taxRate;

    if (this.cs.process.products.unfall.input.insured_persons.length === 2) // 2 VP = +10% Rabatt
    { result.rabatte = 19; } //Rechnung 10% und dann noch mal 10% = 19%

    if (this.cs.process.products.unfall.input.insured_persons.length > 2) // > 2VP = 15% Rabatt
    { result.rabatte = 23.5; } //Rechnung 10% und dann noch mal 15% = 23,5%


    for (let i = 0; i < this.cs.process.products.unfall.input.insured_persons.length; i++) {
      let person = Object();

      person = JSON.parse(JSON.stringify(this.cs.process.products.unfall.input.insured_persons[i]));
      person.gefahrengruppe = this.getGefahrengruppe(person);

      person.umfang = new Array();

      if (this.getEmpty(person.scope.invaliditaet) !== '') {
        person.umfang.push(this.berechneTarifbaustein('invaliditaet', person.scope.invaliditaet, person.scope.invaliditaet_leistung, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.unfallrentenkapital) !== '') {
        person.umfang.push(this.berechneTarifbaustein('unfallrentenkapital', null, person.scope.unfallrentenkapital, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.unfallrente) !== '') {
        person.umfang.push(this.berechneTarifbaustein('unfallrente', person.scope.unfallrente, person.scope.unfallrente_leistung, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.todesfall_leistung) !== '') {
        person.umfang.push(this.berechneTarifbaustein('todesfall', null, person.scope.todesfall_leistung, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.krankenhaustagegeld) !== '') {
        person.umfang.push(this.berechneTarifbaustein('krankenhaustagegeld', person.scope.krankenhaustagegeld, person.scope.krankenhaustagegeld_leistung, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.tagegeld) !== '') {
        person.umfang.push(this.berechneTarifbaustein('tagegeld', person.scope.tagegeld, person.scope.tagegeld_leistung, person.gefahrengruppe, result));
      }

      if (this.getEmpty(person.scope.gipsgeld_leistung) !== '') {
        person.umfang.push(this.berechneTarifbaustein('gipsgeld', null, person.scope.gipsgeld_leistung, person.gefahrengruppe, result));
      }

      if (person.scope.modules.is_happy_holiday) {
        person.umfang.push(this.berechneTarifbaustein('happy_holiday', null, 1, person.gefahrengruppe, result));
      }

      if (person.scope.modules.is_reha_management) {
        person.umfang.push(this.berechneTarifbaustein('reha_management', null, 1, person.gefahrengruppe, result));
      }

      if (person.scope.modules.is_pflege_hilfsleistungen) {
        person.umfang.push(this.berechneTarifbaustein('pflege_hilfsleistungen', null, 1, person.gefahrengruppe, result));
      }

      person.beitragZahlVP = 0;
      person.beitragVersicherungssteuerVP = 0;

      for (let ii = 0; ii < person.umfang.length; ii++) {
        const umfang = person.umfang[ii];

        umfang.beitragNetto = this.calcMitAnzZahlungen(umfang.beitragNetto, zahlweise);
        umfang.beitragVersicherungssteuer = this.calcMitAnzZahlungen(umfang.beitragVersicherungssteuer, zahlweise);
        person.beitragZahlVP += umfang.beitragNetto + umfang.beitragVersicherungssteuer;

        person.beitragVersicherungssteuerVP += umfang.beitragVersicherungssteuer;
        const leistungBaustein = Object();
        leistungBaustein.Leistung = umfang.bezeichnung;
        leistungBaustein.Wert = umfang.wert;

        result.unfallNetto += this.calcMitAnzZahlungen(umfang.beitragNetto, zahlweise);
        result.unfallVersicherungssteuer += this.calcMitAnzZahlungen(umfang.beitragVersicherungssteuer, zahlweise);
        result.unfallZahl += this.calcMitAnzZahlungen(umfang.beitragNetto, zahlweise) + this.calcMitAnzZahlungen(umfang.beitragVersicherungssteuer, zahlweise);
      }
      person.beitragZahlVPGemZW = person.beitragZahlVP / zahlweise;
      result.berechnungsergebnisse.push(person);
    }
    result.unfallZahlGemZW = result.unfallZahl / zahlweise;

    result.gesamtNetto = result.unfallNetto;
    result.gesamtVersicherungssteuer = result.unfallVersicherungssteuer;
    result.gesamtZahl = result.unfallZahl;
    result.gesamtZahlGemZW = result.unfallZahlGemZW;


    result.unfallSteuerProz = this.us.unfallData.taxRate;

    this.cs.process.products.unfall.calculation = { ...result };

  }
}
