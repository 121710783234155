/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { ContextService } from './context.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isLoggedIn = false;
  userSigned = false;

  constructor(private http: HttpClient, private navCtrl: NavController, private cs: ContextService,
    private iab: InAppBrowser, private ngZone: NgZone, private platform: Platform) { }

  async login(email: string, password: string) {
    return this.http.post(`${environment.apiUrl}/api/auth/login`, { email, password }).toPromise();
  }

  async registerSignature() {
    const id_user = localStorage.getItem('userId');
    return this.http.post(`${environment.apiUrl}/api/user/register_signature`, { id_user }).toPromise();
  }

  async getUserStatus() {
    const id_user = localStorage.getItem('userId');
    const clientVersion = '3.0.3';
    return this.http.post(`${environment.apiUrl}/api/user/status`, { id_user, clientVersion }).toPromise();
  }

  async refreshToken() {
    return this.http.post(`${environment.apiUrl}/api/auth/refresh`, {}).toPromise();
  }

  async forgotPassword() {
    return this.http.post(`${environment.apiUrl}/api/auth/forgot_password_init`, { email: this.cs.user.email }).toPromise();
  }

  async resetPassword() {
    const email = this.cs.user.email;
    const password = this.cs.user.password;
    const password_confirmation = this.cs.user.passwordConfirm;
    const token = this.cs.user.resetToken;
    return this.http.post(`${environment.apiUrl}/api/auth/forgot_password_reset`, { email, password, password_confirmation, token }).toPromise();
  }

  async getTippgeber() {
    return this.http.post(`${environment.apiUrl}/api/user/get_tippgeber`, {}).toPromise();
  }

  async verifyIban(iban: string) {
    return this.http.post(`${environment.apiUrl}/api/verify_iban`, { iban }).toPromise();
  }

  async startProcess(requestPayload: any) {
    return this.http.post(`${environment.apiUrl}/api/process/start`, requestPayload).toPromise();
  }

  async storeEncryptedProcess(requestPayload: any) {
    return this.http.post(`${environment.apiUrl}/api/process/encrypted/store`, requestPayload).toPromise();
  }

  async getEncryptedProcess(requestPayload: any) {
    return this.http.post(`${environment.apiUrl}/api/process/encrypted/get`, requestPayload).toPromise();
  }

  getSignKey(accessKey: any) {
    return this.http.post(`${environment.apiUrl}/api/frontend/get_sign_key`, { key: accessKey });
  }

  async openSignaturePage() {
    const response = await this.registerSignature();
    const signaturePageUrl = response['data']['url'];
    if (this.platform.is('capacitor')) {
      const browser = this.iab.create(signaturePageUrl);
      browser.on('exit').subscribe(async () => {
        const res = await this.getUserStatus();
        if (res['data']['status'].length === 0) {
          this.ngZone.run(() => {
            this.userSigned = true;
            localStorage.setItem('userSigned', String(this.userSigned));
          });
        }
      });
    } else {
      window.open(signaturePageUrl, '_blank');
    }
  }

  async signDocument(signaturePageUrl: string) {
    if (this.platform.is('capacitor')) {
      this.iab.create(signaturePageUrl);
    } else {
      window.open(signaturePageUrl, '_blank');
    }
  }

  logout() {
    this.cs.cleanContext();
    this.isLoggedIn = false;
    this.userSigned = false;
    this.cs.user = { email: '', password: '', passwordConfirm: '', resetToken: '', disabled: '' };
    localStorage.removeItem('authToken');
    localStorage.removeItem('userSigned');
    localStorage.removeItem('userId');
    this.navCtrl.navigateRoot('/home');
  }
}
