// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let apiURL = 'https://provb-be.dicontract.de';
let isDev = true;

if (document.URL.indexOf('provb-app.dicontract.de') !== -1) {
  apiURL = 'https://provb-be.dicontract.de';
  isDev = false;
}
if (document.URL.indexOf('bp-demo-internal-mobile.dicontract.de') !== -1) {
  apiURL = 'https://bp-demo-internal-be.dicontract.de';
  isDev = false;
}
if (document.URL.indexOf('provb-app.test.dicontract.de') !== -1) {
  apiURL = 'https://provb-be.test.dicontract.de';
  isDev = false;
}
if (document.URL.indexOf('8001') !== -1) {
  apiURL = 'http://127.0.0.1:8080';
  isDev = false;
}
if (document.URL.indexOf('8101') !== -1) {
  apiURL = 'http://127.0.0.1:8080';
  isDev = false;
}
console.log('API-URL', apiURL);
export const environment = {
  production: false,
  apiUrl: apiURL,
  isDev,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
