import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  @Input() title = 'prOVB 3.0.3';

  constructor(public router: Router) { }

}
