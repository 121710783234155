/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WohngebaeudeData2023Service {
  indexierung = 1.1473;
  glasData = {
    version: '20220228',

    product: {
      name: 'Glasversicherung',
      short: 'Glas'
    },

    taxRate: 0.19,

    tarife: {
      premium: {
        name: 'Premium',
        perqm: 25.04
      },
      premium_plus: {
        name: 'Premium Plus',
        perqm: 36.35
      }
    }
  };

  wohngebaeudeData = {
    version: '20220228',
    taxRate: 0.1634,
    taxRateElementar: 0.1634,
    product: {
      name: 'Wohngebäudeversicherung',
      short: 'Wohngebäude'
    },
    alter: {
      neu: 'Neubau',
      ab8: 'ab 8 Jahren',
      ab17: 'ab 17 Jahren'
    },
    tarife: {
      premium: {
        name: 'Premium',
        benefit: 581,
        smart_home: 6379,
        zonen: {
          neu: {
            '1/1': 96.30 * this.indexierung,
            '2/1': 112.65 * this.indexierung,
            '3/1': 136.28 * this.indexierung,
            '4/1': 156.27 * this.indexierung,
            '1/2': 116.29 * this.indexierung,
            '2/2': 132.64 * this.indexierung,
            '3/2': 150.82 * this.indexierung,
            '4/2': 176.26 * this.indexierung,
          },
          ab8: {
            '1/1': 120.38 * this.indexierung,
            '2/1': 140.83 * this.indexierung,
            '3/1': 170.35 * this.indexierung,
            '4/1': 195.35 * this.indexierung,
            '1/2': 145.37 * this.indexierung,
            '2/2': 165.83 * this.indexierung,
            '3/2': 188.52 * this.indexierung,
            '4/2': 220.33 * this.indexierung,
          },
          ab17: {
            '1/1': 147.46 * this.indexierung,
            '2/1': 172.50 * this.indexierung,
            '3/1': 208.69 * this.indexierung,
            '4/1': 239.28 * this.indexierung,
            '1/2': 178.08 * this.indexierung,
            '2/2': 203.11 * this.indexierung,
            '3/2': 230.94 * this.indexierung,
            '4/2': 269.89 * this.indexierung,
          }
        },
        zuersZonen: {
          1: 54.29 * this.indexierung,
          2: 218.27 * this.indexierung,
          3: 960.00 * this.indexierung,
        }
      },
      premium_plus: {
        name: 'Premium Plus',
        benefit: 581,
        smart_home: 6379,
        zonen_ohne_ug_mg: {
          neu: {
            '1/1': 121.15 * this.indexierung,
            '2/1': 141.35 * this.indexierung,
            '3/1': 143.37 * this.indexierung,
            '4/1': 163.56 * this.indexierung,
            '1/2': 169.62 * this.indexierung,
            '2/2': 189.81 * this.indexierung,
            '3/2': 195.87 * this.indexierung,
            '4/2': 214.04 * this.indexierung,
          },
          ab8: {
            '1/1': 151.44 * this.indexierung,
            '2/1': 175.67 * this.indexierung,
            '3/1': 181.73 * this.indexierung,
            '4/1': 205.96 * this.indexierung,
            '1/2': 212.02 * this.indexierung,
            '2/2': 238.27 * this.indexierung,
            '3/2': 244.33 * this.indexierung,
            '4/2': 268.56 * this.indexierung,
          },
          ab17: {
            '1/1': 185.77 * this.indexierung,
            '2/1': 216.06 * this.indexierung,
            '3/1': 222.12 * this.indexierung,
            '4/1': 252.40 * this.indexierung,
            '1/2': 260.48 * this.indexierung,
            '2/2': 292.79 * this.indexierung,
            '3/2': 298.85 * this.indexierung,
            '4/2': 329.13 * this.indexierung,
          }
        },
        zonen: {
          neu: {
            '1/1': 132.69 * this.indexierung,
            '2/1': 154.81 * this.indexierung,
            '3/1': 157.02 * this.indexierung,
            '4/1': 179.13 * this.indexierung,
            '1/2': 185.77 * this.indexierung,
            '2/2': 207.88 * this.indexierung,
            '3/2': 214.52 * this.indexierung,
            '4/2': 234.42 * this.indexierung,
          },
          ab8: {
            '1/1': 165.87 * this.indexierung,
            '2/1': 192.40 * this.indexierung,
            '3/1': 199.04 * this.indexierung,
            '4/1': 225.58 * this.indexierung,
            '1/2': 232.21 * this.indexierung,
            '2/2': 260.96 * this.indexierung,
            '3/2': 267.60 * this.indexierung,
            '4/2': 294.13 * this.indexierung,
          },
          ab17: {
            '1/1': 203.46 * this.indexierung,
            '2/1': 236.63 * this.indexierung,
            '3/1': 243.27 * this.indexierung,
            '4/1': 276.44 * this.indexierung,
            '1/2': 285.29 * this.indexierung,
            '2/2': 320.67 * this.indexierung,
            '3/2': 327.31 * this.indexierung,
            '4/2': 360.48 * this.indexierung,
          }
        },
        zuersZonen: {
          1: 54.29 * this.indexierung,
          2: 218.27 * this.indexierung,
          3: 960.00 * this.indexierung,
        }
      }
    },
  };

  constructor() { }
}
