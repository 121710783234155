/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ZuersService } from 'src/app/services/zuers.service';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-client-fields',
  templateUrl: './client-fields.component.html',
  styleUrls: ['./client-fields.component.scss'],
})
export class ClientFieldsComponent {

  @ViewChild('zipInput') zipInput: IonInput;
  @ViewChild('streetInput') streetInput: IonInput;

  showRestOfFields = false;
  streets = undefined;
  streetFromService = '';
  firstnameValid = '';
  lastnameValid = '';
  birthdateValid = '';
  zipValid = '';
  streetValid = '';
  housenumberValid = '';
  phoneValid = '';
  emailValid = '';

  constructor(public cs: ContextService, public zuers: ZuersService, public notificationService: NotificationService, public modalCtrl: ModalController, public vs: ValidationService) { }

  validateFormField(fieldName: string) {
    if (fieldName === 'firstname') {
      this.firstnameValid = this.vs.validateField(this.cs.process.antrag.customer.firstname);
    }
    if (fieldName === 'lastname') {
      this.lastnameValid = this.vs.validateField(this.cs.process.antrag.customer.lastname);
    }
    if (fieldName === 'birthdate') {
      this.birthdateValid = this.vs.validateField(this.cs.process.antrag.customer.birthdate);
    }
    if (fieldName === 'zip') {
      this.zipValid = this.vs.validatePlz(this.cs.process.antrag.customer.zip);
    }
    if (fieldName === 'street') {
      this.streetValid = this.vs.validateField(this.cs.process.antrag.customer.street);
    }
    if (fieldName === 'housenumber') {
      this.housenumberValid = this.vs.validateField(this.cs.process.antrag.customer.housenumber);
    }
    if (fieldName === 'phone') {
      this.phoneValid = this.vs.validateField(this.cs.process.antrag.customer.phone);
    }
    if (fieldName === 'email') {
      this.emailValid = this.vs.validateEmail(this.cs.process.antrag.customer.email);
    }
  }

  fireEvent() {
    this.notificationService.birthdateChanged.emit(true);
  }

  async getAddresses() {
    if (this.streetFromService !== this.cs.process.antrag.customer.street) {
    const zipInputEL = await this.zipInput.getInputElement();
    const streetInputEL = await this.streetInput.getInputElement();
    if (String(this.cs.process.antrag.customer.zip)?.length === 5 && this.cs.process.antrag.customer.street?.length >= 2) {
      const res = await this.zuers.getAddress(this.cs.process.antrag.customer.zip, this.cs.process.antrag.customer.street);
      this.streets = res['streets'];
      if (this.streets && this.streets.length > 1) {
        this.showRestOfFields = true;
        zipInputEL.blur();
        streetInputEL.blur();
        await this.presentModal();
      } else if (this.streets) {
        this.showRestOfFields = true;
        this.streetFromService = this.streets.strasse;
        this.cs.process.antrag.customer.street = this.streets.Strasse;
        this.cs.process.antrag.customer.city = this.streets.Ort;
        zipInputEL.blur();
        streetInputEL.blur();
      } else {
        this.showRestOfFields = false;
        this.streets = null;
        this.cs.process.antrag.customer.housenumber = '';
      }
    } else {
      this.showRestOfFields = false;
      this.streets = null;
      this.streetFromService = '';
      this.cs.process.antrag.customer.housenumber = '';
    }
  }
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AddressModalComponent,
      componentProps: {
        streets: this.streets,
      }
    });

    modal.onDidDismiss()
      .then((data) => {
        const address = data.data['selectedAddress'];
        this.streetFromService = address.Strasse;
        this.cs.process.antrag.customer.street = address.Strasse;
        this.cs.process.antrag.customer.city = address.Ort;
      });

    return await modal.present();
  }

}
